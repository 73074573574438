import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';

const LanguageSelector = () => {
    const { i18n, t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        handleClose();
    };

    return (
        <>
            <Tooltip title={t('Change Language')}>
                <IconButton
                    onClick={handleClick}
                    color="inherit"
                    aria-controls="language-menu"
                    aria-haspopup="true"
                >
                    <LanguageIcon />
                </IconButton>
            </Tooltip>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => changeLanguage('fr')}>Français</MenuItem>
                <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
            </Menu>
        </>
    );
};

export default LanguageSelector;