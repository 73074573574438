import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { UserProvider } from './services/userContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoginPage from './pages/LoginPage';
import AuthenticatedApp from './components/AuthenticatedApp';
import Clarity from '@microsoft/clarity';
import './i18n';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      setUser({ isAuthenticated: true });
    } else {
      setUser({ isAuthenticated: false });
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const projectId = "p2lfztebga";
    Clarity.init(projectId);
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const googleClientId = '667052354387-05j67nanj7vptllv491igml7odpc6k9h.apps.googleusercontent.com';
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <UserProvider value={{ user, setUser }}>
        <Router>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/*"
              element={
                user && user.isAuthenticated ? (
                  <AuthenticatedApp />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
          </Routes>
        </Router>
      </UserProvider>
    </GoogleOAuthProvider>
  );
}

export default App;