export const API_BASE_URL = "https://api.magistral.ai";

export const fetchWithToken = async (url, options = {}) => {
    const accessToken = localStorage.getItem('access_token');
    options.headers = {
        ...options.headers,
        'Authorization': `Bearer ${accessToken}`
    };

    let response = await fetch(url, options);

    if (response.status === 401) {
        const refreshToken = localStorage.getItem('refresh_token');
        const refreshResponse = await fetch(`${API_BASE_URL}/api/auth/refresh`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${refreshToken}`
            }
        });

        if (refreshResponse.ok) {
            const refreshData = await refreshResponse.json();
            localStorage.setItem('access_token', refreshData.access_token);
            options.headers['Authorization'] = `Bearer ${refreshData.access_token}`;
            response = await fetch(url, options);
        } else {
            console.error('Failed to refresh token');
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');

            window.location.href = '/login';

            return null;
        }
    }

    return response;
};

export const login = async (email, password) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/auth/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password }),
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Login failed');
        }
    } catch (error) {
        console.error('Error signing in:', error);
        throw error;
    }
};

export const signUp = async (username, email, password) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/auth/signup`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, email, password }),
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Signup failed');
        }
    } catch (error) {
        console.error('Error signing up:', error);
        throw error;
    }
};

export const loginWithGoogle = async (idToken) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/auth/login_google`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ idtoken: idToken }),
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Google login failed');
        }
    } catch (error) {
        console.error('Error signing in with Google:', error);
        throw error;
    }
};

export const fetchUserInfo = async () => {
    try {
        const response = await fetchWithToken(`${API_BASE_URL}/api/user/info`);
        if (response.ok) {
            const data = await response.json();
            if (data.success && data.info) {
                return data.info;
            } else {
                throw new Error('User info not found in response');
            }
        } else {
            throw new Error('Failed to fetch user info');
        }
    } catch (error) {
        console.error('Error fetching user info:', error);
        throw error;
    }
};

export async function fetchProjectDetails(projectId) {
    try {
        const response = await fetchWithToken(`${API_BASE_URL}/api/user/inventory_data?id=${projectId}`, {
            method: 'GET'
        });
        if (response.ok) {
            const data = await response.json();
            if (data.success) {
                return data;
            } else {
                throw new Error('Project details not found in response');
            }
        } else {
            throw new Error('Failed to fetch project details');
        }
    } catch (error) {
        console.error('Error fetching project details:', error);
        throw error;
    }
}

export async function addManualUrl(id, source, content) {
    try {
        const response = await fetchWithToken(`${API_BASE_URL}/api/func/add_manually_url`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id, source, content })
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to add manual URL');
        }
    } catch (error) {
        console.error('Error adding manual URL:', error);
        throw error;
    }
}

export async function scrapUrls(id, urls) {
    try {
        const response = await fetchWithToken(`${API_BASE_URL}/api/func/scrap_urls`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id, urls })
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to scrap URLs');
        }
    } catch (error) {
        console.error('Error scrapping URLs:', error);
        throw error;
    }
}

export async function scrapGoogle(id, query, numbers) {
    try {
        const response = await fetchWithToken(`${API_BASE_URL}/api/func/scrap_google`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id, query, numbers })
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to scrap Google');
        }
    } catch (error) {
        console.error('Error scrapping Google:', error);
        throw error;
    }
}

export async function fetchStripeInvoices() {
    try {
        const response = await fetchWithToken(`${API_BASE_URL}/stripe/transactions`, {
            method: 'GET'
        });
        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to fetch Stripe invoices');
        }
    } catch (error) {
        console.error('Error fetching Stripe invoices:', error);
        throw error;
    }
}

export async function createStripeCheckoutSession(plan, planType, amount) {
    try {
        const response = await fetchWithToken(`${API_BASE_URL}/stripe/create-checkout-session?plan=${plan}&planType=${planType}&amount=${amount}`, {
            method: 'GET',
        });
        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to create Stripe checkout session');
        }
    } catch (error) {
        console.error('Error creating Stripe checkout session:', error);
        throw error;
    }
}


export async function createInventory(name, mode, tags, desireArticle = false, language = 'francais') {
    try {
        const response = await fetchWithToken(`${API_BASE_URL}/api/user/create_inventory`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name, mode, tags, desireArticle, language })
        });

        if (response.ok) {
            console.log('response', response);
            return await response.json();
        } else {
            throw new Error('Failed to create inventory');
        }
    } catch (error) {
        console.error('Error creating inventory:', error);
        throw error;
    }
}

export async function generateArticle(id) {
    try {
        const response = await fetchWithToken(`${API_BASE_URL}/api/func/generate_article`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id })
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to generate article');
        }
    } catch (error) {
        console.error('Error generating article:', error);
        throw error;
    }
}

export async function addKey(key) {
    try {
        const response = await fetchWithToken(`${API_BASE_URL}/api/func/add_key`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ anthropic: key })
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to add key');
        }
    }
    catch (error) {
        console.error('Error adding key:', error);
        throw error;
    }
}

export async function updateData(id, data, key) {
    try {
        const response = await fetchWithToken(`${API_BASE_URL}/api/func/update_data`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id, data, key })
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to update data');
        }
    } catch (error) {
        console.error('Error updating data:', error);
        throw error;
    }
}

export async function addInstructions(id, language, outline, required, seo, topics, writer, extras, longueurArticle) {
    try {
        const response = await fetchWithToken(`${API_BASE_URL}/api/func/add_instructions`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id, language, outline, required, seo, topics, writer, extras, longueurArticle })
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to add instructions');
        }
    } catch (error) {
        console.error('Error adding instructions:', error);
        throw error;
    }
}

export async function deleteInventory(id) {
    try {
        const response = await fetchWithToken(`${API_BASE_URL}/api/user/delete_inventory`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id })
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to delete inventory');
        }
    } catch (error) {
        console.error('Error deleting inventory:', error);
        throw error;
    }
}

export async function bugReport(data) {
    try {
        const response = await fetchWithToken(`${API_BASE_URL}/api/user/bug_report`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to report bug');
        }
    } catch (error) {
        console.error('Error reporting bug:', error);
        throw error;
    }
}

export async function forgotPassword(email) {
    try {
        const response = await fetch(`${API_BASE_URL}/api/auth/forgot-password`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email })
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to send reset password email');
        }
    } catch (error) {
        console.error('Error sending reset password email:', error);
        throw error;
    }
}

export async function uploadFile(inventoryId, file) {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('inventoryId', inventoryId);

        const response = await fetchWithToken(`${API_BASE_URL}/api/user/upload_file`, {
            method: 'POST',
            body: formData
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to upload file');
        }
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
}

export async function translateArticle(id, language) {
    try {
        const response = await fetchWithToken(`${API_BASE_URL}/api/func/translate_article`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id, language })
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Failed to translate article');
        }
    } catch (error) {
        console.error('Error translating article:', error);
        throw error;
    }
}