import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
    Box, Typography, Paper, Collapse, useTheme, useMediaQuery
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const TableOfContents = ({ content, onHeadingClick }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [expandedH2s, setExpandedH2s] = useState({});
    const [hoveredH2, setHoveredH2] = useState(null);
    const [clickedH2s, setClickedH2s] = useState({});
    const [isAllExpanded, setIsAllExpanded] = useState(false);

    const generateHeadingId = (text) => {
        return text.toLowerCase().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '');
    };

    const tocItems = useMemo(() => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const items = [];
        let currentH2 = null;

        Array.from(doc.querySelectorAll('h2, h3')).forEach(heading => {
            const item = {
                level: parseInt(heading.tagName.charAt(1)),
                title: heading.textContent,
                id: generateHeadingId(heading.textContent),
            };

            if (item.level === 2) {
                currentH2 = item;
                currentH2.children = [];
                items.push(currentH2);
            } else if (item.level === 3 && currentH2) {
                currentH2.children.push(item);
            }
        });

        return items;
    }, [content]);

    const getColor = (level) => {
        const isDarkMode = theme.palette.mode === 'dark';
        switch (level) {
            case 2: return isDarkMode ? '#f48fb1' : '#9b2c2c';
            case 3: return isDarkMode ? '#a5d6a7' : '#2f855a';
            default: return theme.palette.text.primary;
        }
    };

    const getBackgroundColor = () => {
        return theme.palette.mode === 'dark' ? '#1a202c' : '#fafafa';
    };

    const handleH2MouseEnter = useCallback((h2Id) => {
        if (!isMobile) {
            setHoveredH2(h2Id);
            setExpandedH2s(prev => ({ ...prev, [h2Id]: true }));
        }
    }, [isMobile]);

    const handleH2MouseLeave = useCallback((h2Id) => {
        if (!isMobile) {
            setHoveredH2(null);
            if (!clickedH2s[h2Id]) {
                setExpandedH2s(prev => ({ ...prev, [h2Id]: false }));
            }
        }
    }, [clickedH2s, isMobile]);

    const handleH2Click = useCallback((h2Id) => {
        setClickedH2s(prev => {
            const newState = { ...prev, [h2Id]: !prev[h2Id] };
            setExpandedH2s(currExpanded => ({ ...currExpanded, [h2Id]: newState[h2Id] }));
            return newState;
        });
    }, []);

    const handleH3Click = useCallback((h2Id) => {
        setClickedH2s(prev => ({ ...prev, [h2Id]: true }));
        setExpandedH2s(prev => ({ ...prev, [h2Id]: true }));
    }, []);

    const handleItemClick = useCallback((itemId, isH3 = false, parentH2Id = null) => {
        if (isH3) {
            handleH3Click(parentH2Id);
        } else {
            handleH2Click(itemId);
        }
        if (!isMobile) {
            onHeadingClick(itemId);
        }
    }, [isMobile, handleH2Click, handleH3Click, onHeadingClick]);

    const toggleAllExpanded = useCallback(() => {
        const newState = !isAllExpanded;
        setIsAllExpanded(newState);
        setClickedH2s(tocItems.reduce((acc, item) => ({ ...acc, [item.id]: newState }), {}));
        setExpandedH2s(tocItems.reduce((acc, item) => ({ ...acc, [item.id]: newState }), {}));
    }, [isAllExpanded, tocItems]);

    const renderHeading = useCallback((item, isH3 = false, parentH2Id = null) => (
        <Box
            key={item.id}
            onClick={() => handleItemClick(item.id, isH3, parentH2Id)}
            onMouseEnter={() => !isH3 && handleH2MouseEnter(item.id)}
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                mb: 1,
                pl: isH3 ? 4 : 0,
                transition: 'background-color 0.3s ease',
                '&:hover': {
                    backgroundColor: isMobile ? 'transparent' : theme.palette.action.hover,
                    // La ligne suivante a été supprimée :
                    // transform: isMobile ? 'none' : 'translateX(5px)',
                },
                cursor: isMobile ? 'default' : 'pointer',
                borderRadius: '4px', // Ajout d'un léger arrondi pour le cadre de survol
                padding: '1px', // Ajout d'un peu de padding pour que le cadre ne soit pas collé au texte
            }}
        >
            <Box
                sx={{
                    width: 24,
                    height: 24,
                    minWidth: 24,
                    borderRadius: '50%',
                    bgcolor: getColor(isH3 ? 3 : 2),
                    color: theme.palette.getContrastText(getColor(isH3 ? 3 : 2)),
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mr: 1,
                    fontSize: '0.75rem',
                    flexShrink: 0,
                }}
            >
                H{isH3 ? 3 : 2}
            </Box>
            <Typography
                variant="body2"
                sx={{
                    color: theme.palette.text.primary,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                }}
            >
                {item.title}
            </Typography>
        </Box>
    ), [handleItemClick, handleH2MouseEnter, isMobile, theme, getColor]);

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3, ml: 1 }}>
                <Typography variant="h6">Table du contenu</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                            color: 'primary.main',
                        },
                    }}
                    onClick={toggleAllExpanded}
                >
                    <Typography variant="body2" sx={{ mr: 0.5 }}>
                        {isAllExpanded ? 'TOUT REPLIER' : 'TOUT DÉPLIER'}
                    </Typography>
                    {isAllExpanded ? (
                        <ExpandLessIcon fontSize="small" />
                    ) : (
                        <ExpandMoreIcon fontSize="small" />
                    )}
                </Box>
            </Box>

            <Paper
                elevation={3}
                sx={{
                    p: 2,
                    ml: 1,
                    mr: 1,
                    height: '100%',
                    overflowY: 'auto',
                    backgroundColor: getBackgroundColor(),
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor:
                            theme.palette.mode === 'dark'
                                ? 'rgba(255, 255, 255, 0.2)'
                                : 'rgba(0, 0, 0, 0.2)',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor:
                            theme.palette.mode === 'dark'
                                ? 'rgba(255, 255, 255, 0.3)'
                                : 'rgba(0, 0, 0, 0.3)',
                    },
                }}
            >
                {tocItems.map((h2Item) => (
                    <Box 
                        key={h2Item.id}
                        onMouseLeave={() => !isMobile && handleH2MouseLeave(h2Item.id)}
                    >
                        {renderHeading(h2Item)}
                        <Collapse
                            in={expandedH2s[h2Item.id] || clickedH2s[h2Item.id] || hoveredH2 === h2Item.id}
                            timeout={900}
                            sx={{
                                transition: 'all 0.5s ease-in-out',
                            }}
                        >
                            <Box sx={{ ml: 3 }}>
                                {h2Item.children.map((h3Item) =>
                                    renderHeading(h3Item, true, h2Item.id)
                                )}
                            </Box>
                        </Collapse>
                    </Box>
                ))}
            </Paper>
        </>
    );
};

export default TableOfContents;