import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Tooltip } from '@mui/material';

const ProgressButton = ({ status, projectId }) => {
    const [progressData, setProgressData] = useState(() => {
        const savedProgress = localStorage.getItem('multiProjectProgress');
        return savedProgress ? JSON.parse(savedProgress) : {};
    });

    const projectType = status.startsWith('Generating') ? status.split(' ')[3].replace('...', '') : '';
    const currentProgress = progressData[projectId] || { type: projectType, progress: 0 };

    useEffect(() => {
        if (!status.startsWith('Generating')) {
            return;
        }

        // If the project type has changed, reset the progress for this project
        if (currentProgress.type !== projectType) {
            setProgressData(prevData => ({
                ...prevData,
                [projectId]: { type: projectType, progress: 0 }
            }));
        }

        const updateProgress = (updateFn) => {
            setProgressData(prevData => {
                const updatedProjectData = updateFn(prevData[projectId] || { type: projectType, progress: 0 });
                const newData = { ...prevData, [projectId]: updatedProjectData };
                localStorage.setItem('multiProjectProgress', JSON.stringify(newData));
                return newData;
            });
        };

        let interval;
        if (projectType === 'Light') {
            interval = setInterval(() => {
                updateProgress(prevProgress => {
                    let newProgress = prevProgress.progress + 1;
                    return {
                        type: projectType,
                        progress: newProgress > 20 ? 100 : newProgress
                    };
                });
            }, 1000); // 1 second interval for Light
        } else {
            interval = setInterval(() => {
                updateProgress(prevProgress => {
                    let newProgress = prevProgress.progress + 1;
                    switch (projectType) {
                        case 'Seo':
                            return { type: projectType, progress: newProgress > 20 ? 20 : newProgress };
                        case 'Outline':
                            return { type: projectType, progress: newProgress > 40 ? 40 : Math.max(newProgress, 20) };
                        case 'Writer':
                            return { type: projectType, progress: newProgress > 90 ? 90 : Math.max(newProgress, 40) };
                        case 'Extras':
                            return { type: projectType, progress: newProgress > 100 ? 100 : Math.max(newProgress, 90) };
                        default:
                            return prevProgress;
                    }
                });
            }, 2000); // 2 second interval for other types
        }

        return () => clearInterval(interval);
    }, [status, projectId, projectType, currentProgress.type]);

    if (!status.startsWith('Generating')) {
        return null;
    }

    return (
        <Tooltip title={<span style={{ color: 'orange' }}>{`Agent IA ${projectType}`}</span>}>
            <Button
                variant="outlined"
                color="warning"
                size="small"
                startIcon={<CircularProgress size={10} sx={{ color: 'orange' }} />}
                sx={{
                    pl: 1,
                    color: 'orange',
                    borderColor: 'orange',
                    '&:hover': {
                        borderColor: 'orange',
                        backgroundColor: 'rgba(255, 152, 0, 0.04)'
                    }
                }}
            >
                {`${currentProgress.progress}%`}
            </Button>
        </Tooltip>
    );
};

export default ProgressButton;