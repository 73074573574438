import { create } from 'zustand'

const useTranslationStore = create((set) => ({
    isTranslating: false,
    pendingLanguage: null,
    translationId: null,
    setTranslating: (isTranslating, language, id) => set({
        isTranslating,
        pendingLanguage: language,
        translationId: id
    })
}))

export default useTranslationStore;