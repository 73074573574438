import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { useUser } from '../services/userContext';
import { loginWithGoogle, login, signUp, forgotPassword } from '../services/api';
import {
    Box,
    Container,
    TextField,
    Button,
    Typography,
    Divider,
    Paper,
    Avatar,
    CssBaseline,
    Stack,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Link
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../assets/images/logo.png';
import theme from '../assets/js/theme';

function LoginPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { setUser } = useUser();
    const [isLogin, setIsLogin] = useState(true);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
    const [forgotPasswordStatus, setForgotPasswordStatus] = useState('');
    const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const mode = params.get('mode');
        if (mode === 'signup') {
            setIsLogin(false);
        }
    }, [location]);

    const handleGoogleSuccess = async (credentialResponse) => {
        try {
            const userData = await loginWithGoogle(credentialResponse.credential);
            localStorage.setItem('access_token', userData.access_token);
            localStorage.setItem('refresh_token', userData.refresh_token);
            setUser({ isAuthenticated: true });
            navigate('/dashboard');
        } catch (error) {
            console.error('Erreur lors de la connexion avec Google:', error);
            setError('Échec de la connexion avec Google. Veuillez réessayer.');
        }
    };

    const handleGoogleFailure = () => {
        setError('Échec de la connexion avec Google. Veuillez réessayer.');
    };

    const handleForgotPasswordSubmit = async () => {
        if (!forgotPasswordEmail) {
            setForgotPasswordStatus('Veuillez entrer votre adresse email.');
            return;
        }

        setForgotPasswordLoading(true);
        setForgotPasswordStatus('');

        try {
            await forgotPassword(forgotPasswordEmail);
            setForgotPasswordStatus('Un email de réinitialisation a été envoyé à votre adresse.');
            setTimeout(() => {
                setForgotPasswordOpen(false);
                setForgotPasswordEmail('');
                setForgotPasswordStatus('');
            }, 3000);
        } catch (error) {
            setForgotPasswordStatus("Une erreur s'est produite. Veuillez réessayer.");
        } finally {
            setForgotPasswordLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccess('');
        setLoading(true);

        if (!isLogin && password !== confirmPassword) {
            setError('Les mots de passe ne correspondent pas.');
            setLoading(false);
            return;
        }

        try {
            let userData;
            if (isLogin) {
                userData = await login(email, password);
                localStorage.setItem('access_token', userData.access_token);
                localStorage.setItem('refresh_token', userData.refresh_token);
                setUser({ isAuthenticated: true });
                navigate('/dashboard');
            } else {
                userData = await signUp(username, email, password);
                setIsLogin(true);
                setPassword('');
                setConfirmPassword('');
                setSuccess('Inscription réussie. Veuillez vous connecter.');
            }
        } catch (error) {
            console.error('Erreur:', error);
            setError(error.message || (isLogin ? 'Email ou mot de passe incorrect.' : "Échec de l'inscription. Veuillez réessayer."));
        } finally {
            setLoading(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Paper elevation={6} sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 4,
                }}>
                    <Box sx={{ mb: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={logo} alt="Logo" style={{ width: '300px', marginBottom: '20px' }} />
                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                    </Box>
                    <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
                        <Button
                            variant={isLogin ? "contained" : "outlined"}
                            onClick={() => setIsLogin(true)}
                        >
                            Connexion
                        </Button>
                        <Button
                            variant={!isLogin ? "contained" : "outlined"}
                            onClick={() => setIsLogin(false)}
                        >
                            Inscription
                        </Button>
                    </Stack>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
                        {!isLogin && (
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Nom d'utilisateur"
                                name="username"
                                autoComplete="username"
                                autoFocus={!isLogin}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        )}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Adresse email"
                            name="email"
                            autoComplete="email"
                            autoFocus={isLogin}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Mot de passe"
                            type="password"
                            id="password"
                            autoComplete={isLogin ? "current-password" : "new-password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {!isLogin && (
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirmer le mot de passe"
                                type="password"
                                id="confirmPassword"
                                autoComplete="new-password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        )}
                        {isLogin && (
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setForgotPasswordOpen(true);
                                    }}
                                >
                                    Mot de passe oublié ?
                                </Link>
                            </Box>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : (isLogin ? 'Se connecter' : "S'inscrire")}
                        </Button>
                        {error && (
                            <Typography color="error" align="center" sx={{ mt: 2 }}>
                                {error}
                            </Typography>
                        )}
                        {success && (
                            <Typography color="success.main" align="center" sx={{ mt: 2 }}>
                                {success}
                            </Typography>
                        )}
                        <Divider sx={{ my: 3 }}>ou</Divider>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div style={{ colorScheme: 'light' }}>
                                <GoogleLogin
                                    onSuccess={handleGoogleSuccess}
                                    onError={handleGoogleFailure}
                                    useOneTap
                                    theme="outline"
                                    size="large"
                                    text="continue_with"
                                    shape="rectangular"
                                    width="280px"
                                />
                            </div>
                        </Box>
                    </Box>
                </Paper>

                <Dialog open={forgotPasswordOpen} onClose={() => setForgotPasswordOpen(false)}>
                    <DialogTitle>Réinitialisation du mot de passe</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            Entrez votre adresse email pour recevoir un lien de réinitialisation de mot de passe.
                        </Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="forgotPasswordEmail"
                            label="Adresse email"
                            type="email"
                            fullWidth
                            value={forgotPasswordEmail}
                            onChange={(e) => setForgotPasswordEmail(e.target.value)}
                        />
                        {forgotPasswordStatus && (
                            <Typography
                                color={forgotPasswordStatus.includes('error') ? 'error' : 'success.main'}
                                variant="body2"
                                sx={{ mt: 2 }}
                            >
                                {forgotPasswordStatus}
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setForgotPasswordOpen(false)}>Annuler</Button>
                        <Button
                            onClick={handleForgotPasswordSubmit}
                            disabled={forgotPasswordLoading}
                        >
                            {forgotPasswordLoading ? <CircularProgress size={24} /> : 'Envoyer'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </ThemeProvider>
    );
}

export default LoginPage;