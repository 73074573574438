import io from 'socket.io-client';
import { API_BASE_URL } from './api';

let socket;
let connectPromise;
let inventoryUpdateCallback = null;

const socketLog = (message, data) => {
    const SOCKET_LOG_STYLE = 'background: #222; color: #bada55';
    console.log(`%c[Socket] ${message}`, SOCKET_LOG_STYLE, data);
};

export const initSocket = (updateCallback) => {
    inventoryUpdateCallback = updateCallback;

    if (socket) {
        socketLog('Socket already initialized, disconnecting first');
        socket.disconnect();
    }

    socket = io(API_BASE_URL, {
        transports: ['websocket'],
        autoConnect: true,
    });

    connectPromise = new Promise((resolve) => {
        socket.on('connect', () => {
            socketLog('Connected to WebSocket');
            resolve();
        });
    });

    socket.on('disconnect', (reason) => {
        socketLog('Disconnected from WebSocket', reason);
    });

    socket.on('error', (error) => {
        socketLog('Error:', error);
    });

    socket.on('inventory', (data) => {
        socketLog('Received inventory update', data);
        if (inventoryUpdateCallback) {
            inventoryUpdateCallback(data);
        }
    });

    socket.onAny((eventName, ...args) => {
        socketLog(`Received event: ${eventName}`, args);
    });

    return { socket, connectPromise };
};

export const getSocket = () => socket;

export const disconnect = () => {
    if (socket) {
        socketLog('Disconnecting socket');
        socket.disconnect();
        socket = null;
        connectPromise = null;
        inventoryUpdateCallback = null;
    }
};

export const joinRoom = async (room) => {
    if (!socket) {
        socketLog('Socket not initialized. Call initSocket() first.');
        return;
    }

    try {
        await connectPromise;
        socket.emit('join', { room });
        socketLog('Joined room:', room);
    } catch (error) {
        socketLog('Error joining room:', error);
    }
};

export const emitEvent = (eventName, data) => {
    if (socket && socket.connected) {
        socket.emit(eventName, data);
        socketLog(`Emitted event: ${eventName}`, data);
    } else {
        socketLog('Socket is not connected. Unable to emit event.');
    }
};