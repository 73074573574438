import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    Button,
    IconButton,
    Divider,
    useMediaQuery,
    alpha,
    Chip,
    InputAdornment,
    FormControl,
    InputLabel,
    Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useUser } from '../services/userContext';

const CreateProjectModal = ({ open, onClose, onCreateProject, onCreateStandardProject }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [projectName, setProjectName] = useState('');
    const [mode, setMode] = useState('Standard');
    const [tags, setTags] = useState([]);
    const [currentTag, setCurrentTag] = useState('');
    const [desireArticle, setDesireArticle] = useState('');
    const [language, setLanguage] = useState('French');
    const { user } = useUser();
    const hasEnoughCredits = mode === 'Standard' ? user?.credit >= 170 : true;

    const isValid = mode === 'Standard'
        ? desireArticle.trim().length > 0
        : projectName.trim().length > 0;

    const handleCreateProject = () => {
        if (!isValid) return;

        if (mode === 'Standard') {
            onCreateStandardProject(desireArticle, language);
        } else {
            onCreateProject(projectName, mode, tags);
        }

        // Reset form
        setProjectName('');
        setMode('Workflow');
        setTags([]);
        setDesireArticle('');
        setLanguage('French');
        onClose();
    };

    const handleTagChange = (event) => {
        const value = event.target.value;
        if (value.endsWith(',')) {
            addCurrentTag();
        } else {
            setCurrentTag(value);
        }
    };

    const handleAddTag = (event) => {
        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault();
            addCurrentTag();
        }
    };

    const addCurrentTag = () => {
        const trimmedTag = currentTag.trim().replace(/,+$/, '');
        if (trimmedTag !== '' && !tags.includes(trimmedTag)) {
            setTags([...tags, trimmedTag]);
            setCurrentTag('');
        }
    };

    const handleDeleteTag = (tagToDelete) => () => {
        setTags(tags.filter((tag) => tag !== tagToDelete));
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobile ? '90%' : 500,
                maxWidth: '100%',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 1,
                overflow: 'hidden',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 1,
                    bgcolor: alpha(theme.palette.background.paper, 0.9),
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }}>
                    <Typography
                        variant="h6"
                        component="h2"
                        sx={{
                            ml: 1,
                            color: theme.palette.primary.main
                        }}
                    >
                        {t('Créer un nouveau projet d\'article')}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            color: theme.palette.primary.main
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box sx={{ p: 2, bgcolor: theme.palette.background.default }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mb: 2,
                        mt: 2
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            gap: 2,
                            mb: 2
                        }}>
                            <TextField
                                select
                                label={t('Mode IA')}
                                value={mode}
                                onChange={(e) => setMode(e.target.value)}
                                size="medium"
                                fullWidth
                            >
                                <MenuItem disabled sx={{ opacity: 1, fontWeight: 'bold', color: 'text.primary' }}>
                                    Rédaction d'article
                                </MenuItem>
                                <MenuItem value="Standard" sx={{ pl: 4 }}>{t('Standard')}</MenuItem>
                                <MenuItem value="Workflow" sx={{ pl: 4 }}>{t('Advanced')}</MenuItem>
                                {/* <Divider sx={{ my: 1 }} /> */}
                                {/* <MenuItem disabled sx={{ opacity: 1, fontWeight: 'bold', color: 'text.primary' }}>
                                    Spécialisation
                                </MenuItem>
                                <MenuItem value="Legality" sx={{ pl: 4 }}>{t('Legal')}</MenuItem> */}
                            </TextField>
                        </Box>

                        {mode === 'Standard' ? (
                            <>
                                <TextField
                                    label={t('Quel article souhaitez-vous rédiger ?')}
                                    value={desireArticle}
                                    onChange={(e) => setDesireArticle(e.target.value)}
                                    multiline
                                    rows={5}
                                    fullWidth
                                    error={!desireArticle.trim() && desireArticle.length > 0}
                                    helperText={!desireArticle.trim() && desireArticle.length > 0 ? 'Ce champ est requis' : ''}
                                    placeholder="Example: Écrire un article sur la création d'une routine matinale efficace en 5 étapes. Présenter des rituels simples pour augmenter son énergie et sa productivité. Utilisé un ton inspirant et structuré, le mot clé principal est 'routine matinale productivité'"
                                    InputProps={{
                                        sx: {
                                            '& .MuiInputBase-input::placeholder': {
                                                opacity: 0.7,
                                                fontSize: '0.9rem',
                                                fontStyle: 'italic'
                                            },
                                        },
                                    }}
                                />
                                <FormControl fullWidth>
                                    <InputLabel>Language</InputLabel>
                                    <Select
                                        value={language}
                                        onChange={(e) => setLanguage(e.target.value)}
                                        label="Language"
                                    >
                                        <MenuItem value="French">Français</MenuItem>
                                        <MenuItem value="English">Anglais</MenuItem>
                                        <MenuItem value="Spanish">Espagnol</MenuItem>
                                        <MenuItem value="Italian">Italien</MenuItem>
                                        <MenuItem value="German">Allemand</MenuItem>
                                        <MenuItem value="Portuguese">Portugais</MenuItem>
                                    </Select>
                                </FormControl>
                            </>
                        ) : (
                            <>
                                <TextField
                                    label={t('Nom du projet')}
                                    value={projectName}
                                    onChange={(e) => setProjectName(e.target.value)}
                                    inputProps={{ maxLength: 50 }}
                                    size="medium"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{`${projectName.length}/50`}</InputAdornment>,
                                    }}
                                    error={!projectName.trim() && projectName.length > 0}
                                    helperText={!projectName.trim() && projectName.length > 0 ? t('Le nom du projet est requis') : ''}
                                />
                                <TextField
                                    label={t('Tags du projet')}
                                    value={currentTag}
                                    onChange={handleTagChange}
                                    onKeyDown={handleAddTag}
                                    size="medium"
                                    fullWidth
                                    placeholder={t('Appuyez sur Entrée pour ajouter un tag')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        sx: {
                                            '& input::placeholder': {
                                                opacity: 0.7,
                                                color: theme.palette.text.secondary,
                                            },
                                        },
                                    }}
                                />
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {tags.map((tag) => (
                                        <Chip
                                            key={tag}
                                            label={tag}
                                            onDelete={handleDeleteTag(tag)}
                                            color="primary"
                                            variant="outlined"
                                        />
                                    ))}
                                </Box>
                            </>
                        )}
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <Box sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: isMobile ? 'stretch' : 'center',
                        gap: isMobile ? 2 : 0
                    }}>
                        {mode === 'Standard' && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Chip
                                    label="Coût : 170 crédits"
                                    color="primary"
                                    variant="outlined"
                                    sx={{
                                        borderRadius: '8px',
                                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                                        '& .MuiChip-label': {
                                            fontWeight: 'bold'
                                        }
                                    }}
                                />
                            </Box>
                        )}
                        <Tooltip
                            title={(!hasEnoughCredits && mode === 'Standard') ?
                                "Vous n'avez pas assez de crédits pour générer un article. Veuillez recharger votre compte." :
                                ""}
                            placement="top"
                        >
                            <span>
                                <Button
                                    variant="contained"
                                    onClick={handleCreateProject}
                                    fullWidth={isMobile}
                                    disabled={!isValid || !hasEnoughCredits}
                                    sx={{
                                        bgcolor: hasEnoughCredits ? theme.palette.primary.main : 'error.main',
                                        '&:hover': {
                                            bgcolor: hasEnoughCredits ? theme.palette.primary.dark : 'error.dark',
                                        },
                                        '&.Mui-disabled': {
                                            bgcolor: alpha(theme.palette.primary.main, 0.5),
                                            color: theme.palette.primary.contrastText
                                        },
                                        ...(isMobile ? {} : { minWidth: '200px' })
                                    }}
                                    startIcon={hasEnoughCredits ? null : <AccountBalanceWalletIcon />}
                                >
                                    {mode === 'Standard' && !hasEnoughCredits
                                        ? t("Crédits insuffisants")
                                        : t('Créer un projet')}
                                </Button>
                            </span>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default CreateProjectModal;