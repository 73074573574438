import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Paragraph from '@editorjs/paragraph';
import Table from '@editorjs/table';
import Marker from '@editorjs/marker';
import InlineCode from '@editorjs/inline-code';
import {
    Box, Button, useMediaQuery, useTheme, Typography, Tooltip, IconButton,
    FormControl, InputLabel, Select, MenuItem, CircularProgress,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { Parser } from 'htmlparser2';
import { updateData, translateArticle } from '../services/api';
import useTranslationStore from '../stores/translationStore';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

const Editor = React.forwardRef(({ id, details, onHeadingVisibilityChange }, ref) => {
    const [editorReady, setEditorReady] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [projectInfo, setProjectInfo] = useState(null);
    const [language, setLanguage] = useState('default');
    const [availableLanguages, setAvailableLanguages] = useState({});
    const editorInstance = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const { isTranslating, pendingLanguage, translationId, setTranslating } = useTranslationStore();

    useEffect(() => {
        if (details?.data?.Article) {
            const languages = {
                French: Boolean(details.data.Article.Content_French),
                English: Boolean(details.data.Article.Content_English),
                Spanish: Boolean(details.data.Article.Content_Spanish),
                italian: Boolean(details.data.Article.Content_italian),
                German: Boolean(details.data.Article.Content_German),
                Portuguese: Boolean(details.data.Article.Content_Portuguese),
                Breton: Boolean(details.data.Article.Content_Breton),
                Corsican: Boolean(details.data.Article.Content_Corsican),
            };
            setAvailableLanguages(languages);
        }
    }, [details]);

    const editorContainerRef = useRef(null);

    const generateHeadingId = (text) => {
        return text.toLowerCase().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '');
    };

    useImperativeHandle(ref, () => ({
        scrollToHeading: (headingId) => {
            const headingElement = document.getElementById(headingId);
            if (headingElement) {
                headingElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        },
    }));

    useImperativeHandle(ref, () => ({
        scrollToHeading: (headingId) => {
            const headingElement = document.getElementById(headingId);
            if (headingElement) {
                headingElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        },
    }));

    const addHeadingIds = () => {
        if (!editorInstance.current || !editorInstance.current.blocks) {
            console.warn('Editor instance or blocks not available');
            return;
        }

        const blocksCount = editorInstance.current.blocks.getBlocksCount();
        for (let i = 0; i < blocksCount; i++) {
            const block = editorInstance.current.blocks.getBlockByIndex(i);
            const blockHolder = block.holder;
            const headingElement = blockHolder.querySelector('h1,h2,h3,h4,h5,h6');
            if (headingElement) {
                const headingText = headingElement.innerText;
                const headingId = generateHeadingId(headingText);
                if (headingElement.id !== headingId) {
                    headingElement.id = headingId;
                }
            }
        }
    };


    useEffect(() => {
        setEditorReady(true);

        return () => {
            if (editorInstance.current && typeof editorInstance.current.destroy === 'function') {
                editorInstance.current.destroy();
            }
        };
    }, [id]);

    useEffect(() => {
        if (editorReady) {
            initEditor();
        }
    }, [editorReady]);

    const convertHtmlToEditorJsData = (html) => {
        if (!html || typeof html !== 'string') {
            console.warn('Invalid HTML content');
            return { blocks: [] };
        }

        html = html.replace(/<\/?frontmatter>/g, '');

        const blocks = [];
        let currentBlock = null;
        let tagStack = [];

        try {
            const parser = new Parser(
                {
                    onopentag(name, attribs) {
                        if (['b', 'strong', 'i', 'em', 'mark'].includes(name)) {
                            tagStack.push(name);
                        } else if (name === 'a') {
                            const href = attribs.href || '#';
                            tagStack.push({ tag: 'a', href });
                        } else if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(name)) {
                            if (currentBlock) {
                                blocks.push(currentBlock);
                            }
                            currentBlock = {
                                type: 'header',
                                data: {
                                    text: '',
                                    level: parseInt(name[1]),
                                },
                            };
                        } else if (name === 'p') {
                            if (currentBlock) {
                                blocks.push(currentBlock);
                            }
                            currentBlock = {
                                type: 'paragraph',
                                data: {
                                    text: '',
                                },
                            };
                        } else if (name === 'ul' || name === 'ol') {
                            if (currentBlock) {
                                blocks.push(currentBlock);
                            }
                            currentBlock = {
                                type: 'list',
                                data: {
                                    style: name === 'ul' ? 'unordered' : 'ordered',
                                    items: [],
                                },
                            };
                        } else if (name === 'li' && currentBlock && currentBlock.type === 'list') {
                            currentBlock.data.items.push('');
                        } else if (name === 'table') {
                            if (currentBlock) {
                                blocks.push(currentBlock);
                            }
                            currentBlock = {
                                type: 'table',
                                data: {
                                    content: [[]],
                                },
                            };
                        } else if (name === 'tr' && currentBlock && currentBlock.type === 'table') {
                            if (currentBlock.data.content[0].length > 0) {
                                currentBlock.data.content.push([]);
                            }
                        } else if ((name === 'td' || name === 'th') && currentBlock && currentBlock.type === 'table') {
                            const lastRowIndex = currentBlock.data.content.length - 1;
                            currentBlock.data.content[lastRowIndex].push('');
                        }
                    },
                    ontext(text) {
                        let formattedText = text;
                        if (!text.trim()) {
                            return;
                        }
                        if (tagStack.length > 0) {
                            tagStack.forEach((tagInfo) => {
                                if (typeof tagInfo === 'string') {
                                    formattedText = `<${tagInfo}>${formattedText}</${tagInfo}>`;
                                } else if (typeof tagInfo === 'object' && tagInfo.tag === 'a') {
                                    formattedText = `<a href="${tagInfo.href}">${formattedText}</a>`;
                                }
                            });
                        }
                        if (currentBlock) {
                            if (currentBlock.type === 'list') {
                                const lastIndex = currentBlock.data.items.length - 1;
                                currentBlock.data.items[lastIndex] += formattedText;
                            } else if (currentBlock.type === 'table') {
                                const lastRowIndex = currentBlock.data.content.length - 1;
                                const lastCellIndex = currentBlock.data.content[lastRowIndex].length - 1;
                                if (lastCellIndex >= 0) {
                                    currentBlock.data.content[lastRowIndex][lastCellIndex] += formattedText;
                                }
                            } else {
                                currentBlock.data.text += formattedText;
                            }
                        } else {
                            currentBlock = {
                                type: 'paragraph',
                                data: {
                                    text: formattedText,
                                },
                            };
                            blocks.push(currentBlock);
                            currentBlock = null;
                        }
                    },
                    onclosetag(tagname) {
                        if (['b', 'strong', 'i', 'em', 'mark'].includes(tagname)) {
                            tagStack.pop();
                        } else if (tagname === 'a') {
                            tagStack.pop();
                        } else if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'ul', 'ol', 'table'].includes(tagname)) {
                            if (currentBlock) {
                                blocks.push(currentBlock);
                                currentBlock = null;
                            }
                        }
                    },
                },
                { decodeEntities: true }
            );

            parser.write(html);
            parser.end();
        } catch (error) {
            console.error('Error parsing HTML:', error);
            return { blocks: [] };
        }

        return { blocks: blocks.filter(block => block != null) };
    };

    const handleLanguageChange = async (e) => {
        const newLanguage = e.target.value;
        if (newLanguage === 'default') {
            setLanguage(newLanguage);
            reloadEditor(newLanguage);
            return;
        }

        if (!availableLanguages[newLanguage]) {
            setTranslating(false, newLanguage, id);
            setOpenModal(true);
            return;
        }

        setLanguage(newLanguage);
        reloadEditor(newLanguage);
    };

    const handleTranslateConfirm = async () => {
        setOpenModal(false);
        setTranslating(true, pendingLanguage, id);

        try {
            const result = await translateArticle(id, pendingLanguage);
            if (result.success) {
                setAvailableLanguages(prev => ({ ...prev, [pendingLanguage]: true }));
            }
        } catch (error) {
            console.error('Translation failed:', error);
        } finally {
            setTranslating(false, null, id);
        }
    };

    const reloadEditor = async (selectedLanguage, newContent = null) => {
        if (editorInstance.current) {
            editorInstance.current.destroy();
            editorInstance.current = null;
            await initEditor(selectedLanguage, newContent);
        }
    };

    const initEditor = async (selectedLanguage = language) => {
        if (!editorInstance.current) {
            try {
                setProjectInfo({
                    id: details.inventory.id,
                    name: details.inventory.name,
                    mode: details.inventory.mode,
                    createdAt: new Date(details.inventory.created_at).toLocaleDateString()
                });

                const htmlContent = selectedLanguage === 'default'
                    ? details.data.Article.Content
                    : details.data.Article[`Content_${selectedLanguage}`] || '';

                const editorJsData = convertHtmlToEditorJsData(htmlContent);

                const editor = new EditorJS({
                    holder: 'editorjs',
                    tools: {
                        header: { class: Header, inlineToolbar: true },
                        list: { class: List, inlineToolbar: true },
                        paragraph: { class: Paragraph, inlineToolbar: true },
                        table: {
                            class: Table,
                            inlineToolbar: true,
                            config: { rows: 2, cols: 3 }
                        },
                        marker: { class: Marker, inlineToolbar: true },
                        inlineCode: { class: InlineCode, inlineToolbar: true }
                    },
                    data: editorJsData,
                    minHeight: 0,
                    onChange: () => {
                        setHasChanges(true);
                        addHeadingIds();
                    }
                });

                await editor.isReady;
                editorInstance.current = editor;
                addHeadingIds();
            } catch (error) {
                console.error('Editor.js initialization failed:', error);
            }
        }
    };

    const convertEditorJsToHtml = (blocks) => {
        let html = '';
        blocks.forEach(block => {
            switch (block.type) {
                case 'header':
                    html += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
                    break;
                case 'paragraph':
                    html += `<p>${block.data.text}</p>`;
                    break;
                case 'list':
                    const listType = block.data.style === 'ordered' ? 'ol' : 'ul';
                    html += `<${listType}>`;
                    block.data.items.forEach(item => {
                        html += `<li>${item}</li>`;
                    });
                    html += `</${listType}>`;
                    break;
                case 'table':
                    html += '<table>';
                    block.data.content.forEach(row => {
                        html += '<tr>';
                        row.forEach(cell => {
                            html += `<td>${cell}</td>`;
                        });
                        html += '</tr>';
                    });
                    html += '</table>';
                    break;
            }
        });
        return html;
    };

    const handleSave = async () => {
        if (editorInstance.current) {
            try {
                const savedData = await editorInstance.current.save();
                const htmlContent = convertEditorJsToHtml(savedData.blocks);
                await updateData(id, htmlContent, 'Article');
                setHasChanges(false);

                // Émettre un événement avec le nouveau contenu
                const event = new CustomEvent('articleContentUpdated', {
                    detail: { content: htmlContent }
                });
                window.dispatchEvent(event);

                console.log('Content saved successfully');
            } catch (error) {
                console.error('Failed to save content:', error);
            }
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    if (!editorReady) {
        return <Box>Loading editor...</Box>;
    }

    return (
        <Box sx={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
                backgroundColor: theme.palette.background.paper,
                padding: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                boxShadow: '0px 2px 4px 2px rgba(0,0,0,0.2)',
                borderBottom: `1px solid ${theme.palette.divider}`,
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Button
                        onClick={handleGoBack}
                        variant="outlined"
                        size="medium"
                        sx={{ minWidth: 0, p: 1 }}
                    >
                        <ArrowBackIcon />
                    </Button>
                    {!isMobile && projectInfo && (
                        <>
                            <Typography variant="h6" noWrap>{projectInfo.name}</Typography>
                            <Tooltip
                                title={
                                    <Box sx={{ p: 0 }}>
                                        <Typography variant="body2">ID: {projectInfo.id}</Typography>
                                        <Typography variant="body2">Mode: {projectInfo.mode === 'Workflow' ? 'Advanced' : projectInfo.mode}</Typography>
                                        <Typography variant="body2">Créé le: {projectInfo.createdAt}</Typography>
                                    </Box>
                                }
                                open={tooltipOpen}
                                onClose={() => setTooltipOpen(false)}
                                onOpen={() => setTooltipOpen(true)}
                                placement="right"
                                arrow
                            >
                                <IconButton
                                    size="small"
                                    onClick={() => setTooltipOpen(!tooltipOpen)}
                                    sx={{ width: 24, height: 24, padding: 0, marginLeft: 1 }}
                                >
                                    <InfoOutlinedIcon fontSize="small" color="primary" />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <FormControl size="small" sx={{ minWidth: 200 }}>
                        <InputLabel>Langue de rédaction</InputLabel>
                        <Select
                            value={language}
                            onChange={handleLanguageChange}
                            label="Langue de rédaction"
                            disabled={isTranslating && translationId === id}
                            sx={{
                                '& .MuiSelect-icon': {
                                    color: isTranslating && translationId === id ? 'primary.main' : 'inherit'
                                }
                            }}
                            IconComponent={(props) => isTranslating && translationId === id ?
                                <CircularProgress size={16} color="primary" /> :
                                <KeyboardArrowDown {...props} />}
                        >
                            <MenuItem value="default">Version principale</MenuItem>                            {Object.entries({
                                French: "Français",
                                English: "Anglais",
                                Spanish: "Espagnol",
                                italian: "Italien",
                                German: "Allemand",
                                Portuguese: "Portugais",
                                Breton: "Breton",
                                Corsican: "Corse"
                            }).map(([key, label]) => (
                                <MenuItem
                                    key={key}
                                    value={key}
                                    sx={{
                                        color: availableLanguages[key] ? 'inherit' : 'text.disabled'
                                    }}
                                >
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        variant="outlined"
                        startIcon={<SaveIcon />}
                        onClick={handleSave}
                        disabled={!hasChanges}
                    >
                        Sauvegarder
                    </Button>
                </Box>
            </Box>

            <Dialog open={openModal} onClose={() => setOpenModal(false)}>
                <DialogTitle>Traduction de l'article</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous générer la traduction de cet article ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)}>Annuler</Button>
                    <Button onClick={handleTranslateConfirm} autoFocus>Oui</Button>
                </DialogActions>
            </Dialog>
            <style>
                {`
                #editorjs {
                    background-color: #fafafa;
                    color: #2d3748;
                    padding-bottom: 60px;
                }

                #editorjs h1 {
                    color: #2b6cb0 !important;
                }

                #editorjs h2 {
                    color: #9b2c2c !important;
                }

                #editorjs h3,
                #editorjs h4,
                #editorjs h5 {
                    color: #2f855a !important;
                }

                #editorjs h6 {
                    color: #4a5568 !important;
                }

                #editorjs b,
                #editorjs strong {
                    font-weight: bold;
                    color: #2d3748;
                }

                #editorjs i,
                #editorjs em {
                    font-style: italic;
                    color: #2d3748;
                }

                /* Liens */
                #editorjs a {
                    color: #e65100 !important; /* Orange foncé */
                    text-decoration: underline;
                    transition: color 0.3s ease;
                }

                #editorjs a:hover {
                    color: #bf360c !important; /* Orange rougeâtre au survol */
                }

                #editorjs a:hover {
                    color: #2b6cb0;
                }

                #editorjs mark {
                    background-color: #faf089;
                    color: #2d3748;
                }

                /* Listes non ordonnées */
                #editorjs ul {
                    list-style-type: disc;
                    margin-left: 20px;
                }

                #editorjs ul li {
                    color: inherit;
                }

                /* Couleur des puces */
                #editorjs ul li::marker {
                    color: #2f855a; /* Vert clair comme les H3 */
                }

                /* Listes ordonnées */
                #editorjs ol {
                    list-style-type: decimal;
                    margin-left: 20px;
                }

                #editorjs ol li {
                    color: inherit;
                }

                /* Couleur des numéros */
                #editorjs ol li::marker {
                    color: #2f855a; /* Vert clair comme les H3 */
                }

                #editorjs blockquote {
                    border-left: 4px solid #cbd5e0;
                    padding-left: 16px;
                    color: #4a5568;
                    background-color: #edf2f7;
                }

                #editorjs code {
                    background-color: #e2e8f0;
                    color: #2d3748;
                    padding: 2px 4px;
                    border-radius: 4px;
                    font-family: 'Courier New', monospace;
                }
                /* Custom*/

                #editorjs .ce-block:hover:not(.ce-block--selected) {
                    background-color: rgba(0, 0, 0, 0.05) !important;
                }
                #editorjs .ce-block--selected {
                    background-color: rgba(37, 99, 235, 0.1) !important;
                }
                #editorjs .ce-block--selected .ce-block__content {
                    background-color: transparent !important;
                }
                #editorjs ::selection {
                    background-color: rgba(37, 99, 235, 0.3) !important;
                }
                #editorjs ::-moz-selection {
                    background-color: rgba(37, 99, 235, 0.3) !important;
                }
                #editorjs::-webkit-scrollbar {
                    width: 8px;
                }
                #editorjs::-webkit-scrollbar-track {
                    background: transparent;
                }
                #editorjs::-webkit-scrollbar-thumb {
                    background-color: rgba(155, 155, 155, 0.5);
                    border-radius: 4px;
                    border: 2px solid transparent;
                    background-clip: content-box;
                }
                #editorjs::-webkit-scrollbar-thumb:hover {
                    background-color: rgba(155, 155, 155, 0.7);
                }
                #editorjs {
                    scrollbar-width: thin;
                    scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
                }
                @media (max-width: ${theme.breakpoints.values.sm}px) {
                    #editorjs {
                        padding-left: 16px !important;
                        padding-right: 16px !important;
                    }
                    #editorjs .ce-block__content,
                    #editorjs .ce-toolbar__content {
                        max-width: calc(100% - 32px) !important;
                    }
                    #editorjs .ce-toolbar__actions {
                        right: 0;
                    }
                }
                ${theme.palette.mode === 'dark' ? `
                #editorjs {
                    background-color: #1a202c; /* Fond sombre */
                    color: #e2e8f0; /* Texte par défaut */
                    padding-bottom: 60px;
                }

                /* Titres */
                #editorjs h1 {
                    color: #90caf9 !important; /* Couleur primaire */
                }

                #editorjs h2 {
                    color: #f48fb1 !important; /* Couleur secondaire */
                }

                #editorjs h3,
                #editorjs h4,
                #editorjs h5 {
                    color: #a5d6a7 !important; /* Couleur tertiaire */
                }

                #editorjs h6 {
                    color: #e2e8f0 !important; /* Même que le texte par défaut */
                }

                /* Texte en gras et en italique */
                #editorjs b,
                #editorjs strong {
                    font-weight: bold;
                    color: #e2e8f0;
                }

                #editorjs i,
                #editorjs em {
                    font-style: italic;
                    color: #e2e8f0;
                }

                /* Listes non ordonnées */
                #editorjs ul {
                    list-style-type: disc;
                    margin-left: 20px;
                }
                
                #editorjs ul li {
                    color: inherit;
                }
                
                /* Couleur des puces */
                #editorjs ul li::marker {
                    color: #a5d6a7; /* Vert clair comme les H3 */
                }
                
                /* Listes ordonnées */
                #editorjs ol {
                    list-style-type: decimal;
                    margin-left: 20px;
                }
                
                #editorjs ol li {
                    color: inherit;
                }
                
                /* Couleur des numéros */
                #editorjs ol li::marker {
                    color: #a5d6a7; /* Vert clair comme les H3 */
                }

                /* Liens */
                #editorjs a {color: #ff9800 !important;} /* Orange vif */
                #editorjs a:hover {color: #ffb74d !important;} /* Orange clair */

                #editorjs .ce-toolbar__plus,
                #editorjs .ce-toolbar__settings-btn,
                #editorjs .cdx-button,
                #editorjs .ce-conversion-tool__icon {
                    color: white !important;
                }
                #editorjs .ce-toolbar__plus:hover,
                #editorjs .ce-toolbar__settings-btn:hover,
                #editorjs .cdx-button:hover,
                #editorjs .ce-conversion-tool:hover .ce-conversion-tool__icon {
                    background-color: rgba(255, 255, 255, 0.1) !important;
                }
                #editorjs .ce-block:hover:not(.ce-block--selected) {
                    background-color: rgba(255, 255, 255, 0.05) !important;
                }
                #editorjs::-webkit-scrollbar-thumb {
                    background-color: rgba(255, 255, 255, 0.3);
                }
                #editorjs::-webkit-scrollbar-thumb:hover {
                    background-color: rgba(255, 255, 255, 0.5);
                }
                #editorjs {
                    scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
                }
                #editorjs .ce-toolbar {
                    background-color: #333 !important;
                }
                ` : ''}
                .ce-inline-tool-input--showed {
                    color: black !important;
                }
            `}
            </style>
            <Box
                id="editorjs"
                ref={editorContainerRef}
                sx={{
                    flex: 1,
                    overflowY: 'auto',
                    padding: 2,
                    paddingLeft: { xs: 2, sm: 8 },
                    paddingRight: { xs: 2, sm: 8 },
                    wordWrap: 'break-word',
                    '& .codex-editor': {
                        width: '100% !important',
                        maxWidth: '100% !important',
                    },
                    '& .codex-editor__redactor': {
                        width: '100% !important',
                        maxWidth: '100% !important',
                    },
                    '& .ce-block__content': {
                        width: '100% !important',
                        maxWidth: '100% !important',
                    },
                    '& .ce-toolbar__content': {
                        maxWidth: '100% !important',
                    },
                }}
            />
        </Box>
    );
});

export default Editor;