import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

const ThemeAwareTitle = () => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '0px', height: '40px' }}>
            <img
                src="https://geeks.fr/magistral/beta.png"
                alt="Logo BETA"
                style={{
                    height: '50px',
                    width: '110px',
                    objectFit: 'contain',
                    filter: isDarkMode ? 'invert(1)' : 'invert(0)'
                }}
            />
        </Box>
    );
};

export default ThemeAwareTitle;