import React, { useState, useEffect, useCallback } from 'react';
import {
    Typography,
    Box,
    TextField,
    Button,
    Paper,
    Snackbar,
    IconButton,
    CircularProgress,
    useMediaQuery,
    useTheme,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Card,
    CardContent,
    Avatar,
    Chip,
    Grid
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import BugReportIcon from '@mui/icons-material/BugReport';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { bugReport, fetchUserInfo } from '../services/api';
import { useUser } from '../services/userContext';
import { useDropzone } from 'react-dropzone';

const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'rgba(255, 255, 255, 0.23)',
        },
        '&:hover fieldset': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main,
        },
    },
}));

const ImagePreview = styled('img')({
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '4px',
    marginTop: '8px',
});

const SupportCard = styled(Card)(({ theme }) => ({
    cursor: 'pointer',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: theme.shadows[4],
    },
}));

const DropzoneArea = styled('div')(({ theme }) => ({
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: '20px',
    textTransform: 'none',
    fontWeight: 'bold',
    padding: '10px 20px',
}));

function SupportPage() {
    const { t } = useTranslation();
    const { user, setUser } = useUser();
    const navigate = useNavigate();
    const [bugReportData, setBugReportData] = useState({ subject: '', description: '', image: null });
    const [imagePreview, setImagePreview] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const initializeUserInfo = async () => {
            try {
                const userInfo = await fetchUserInfo();
                if (userInfo.success) {
                    setUser(prevUser => ({ ...prevUser, ...userInfo.info }));
                }
            } catch (error) {
                console.error(t('Error fetching user info:'), error);
            }
        };

        initializeUserInfo();
    }, [setUser, t]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBugReportData(prev => ({ ...prev, [name]: value }));
    };

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setBugReportData(prev => ({ ...prev, image: file }));
            const reader = new FileReader();
            reader.onload = (e) => setImagePreview(e.target.result);
            reader.readAsDataURL(file);
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false
    });

    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64 = reader.result.split(',')[1];
                resolve(base64);
            };
            reader.onerror = error => reject(error);
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const data = {
                subject: bugReportData.subject,
                description: bugReportData.description,
                image_base64: bugReportData.image ? await toBase64(bugReportData.image) : ''
            };

            const response = await bugReport(data);

            if (response.success) {
                setSnackbarMessage(t('Votre message a été envoyé avec succès!'));
                setBugReportData({ subject: '', description: '', image: null });
                setImagePreview(null);
                setActiveStep(2);
            } else {
                setSnackbarMessage(t('Erreur lors de l\'envoi du message.'));
            }
        } catch (error) {
            console.error('Erreur:', error);
            setSnackbarMessage(t('Une erreur s\'est produite. Veuillez réessayer.'));
        } finally {
            setIsSubmitting(false);
            setOpenSnackbar(true);
        }
    };

    const supportOptions = [
        { title: t('General help'), icon: <HelpOutlineIcon />, description: t('Need help using our platform?') },
        { title: t('Report a bug'), icon: <BugReportIcon />, description: t('Encountered a technical issue?') },
        { title: t('Suggest an improvement'), icon: <LightbulbIcon />, description: t('Have an idea to improve our service?') },
    ];

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setBugReportData(prev => ({ ...prev, subject: option.title }));
        setActiveStep(1);
    };


    return (
        <Box sx={{ maxWidth: 1280, margin: '0 auto', padding: 3 }}>
            <Box sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: isMobile ? 'flex-start' : 'baseline',
                gap: isMobile ? 2 : 0,
            }}>
                <Box>
                    <Typography className="custom-title">
                        {t('Support, Help & Suggestions')}
                    </Typography>
                    <Typography className="custom-subtitle">
                        {t('How can we help you today?')}
                    </Typography>
                </Box>
            </Box>

            <Paper elevation={3} sx={{ p: 1.5, mb: 4, borderRadius: '6px' }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step>
                        <StepLabel>{t('Choose an option')}</StepLabel>
                        <StepContent>
                            <Grid container spacing={2}>
                                {supportOptions.map((option, index) => (
                                    <Grid item xs={12} sm={4} key={index}>
                                        <SupportCard onClick={() => handleOptionSelect(option)}>
                                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                                <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56, mb: 2 }}>
                                                    {option.icon}
                                                </Avatar>
                                                <Typography variant="h6" gutterBottom>
                                                    {option.title}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {option.description}
                                                </Typography>
                                            </CardContent>
                                        </SupportCard>
                                    </Grid>
                                ))}
                            </Grid>
                        </StepContent>
                    </Step>

                    <Step>
                        <StepLabel>{t('Detail your request')}</StepLabel>
                        <StepContent>
                            <form onSubmit={handleSubmit}>
                                <StyledTextField
                                    fullWidth
                                    label={t('Subject')}
                                    name="subject"
                                    value={bugReportData.subject}
                                    onChange={handleInputChange}
                                    required
                                    InputProps={{ readOnly: true }}
                                />
                                <StyledTextField
                                    fullWidth
                                    label={t('Description')}
                                    name="description"
                                    value={bugReportData.description}
                                    onChange={handleInputChange}
                                    multiline
                                    rows={4}
                                    required
                                />
                                <DropzoneArea {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {isDragActive ? (
                                        <Typography>{t('Drop the image here...')}</Typography>
                                    ) : (
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <CloudUploadIcon sx={{ fontSize: 48, mb: 1 }} />
                                            <Typography>
                                                {t('Drag and drop an image here, or click to select a file')}
                                            </Typography>
                                        </Box>
                                    )}
                                    {imagePreview && <ImagePreview src={imagePreview} alt="Preview" />}
                                </DropzoneArea>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                    <StyledButton
                                        onClick={() => setActiveStep(0)}
                                        variant="outlined"
                                        startIcon={<ArrowBackIcon />}
                                    >
                                        {t('Back')}
                                    </StyledButton>
                                    <StyledButton
                                        type="submit"
                                        variant="outlined"
                                        disabled={isSubmitting}
                                        endIcon={isSubmitting ? <CircularProgress size={24} /> : <SendIcon />}
                                    >
                                        {t('Send')}
                                    </StyledButton>
                                </Box>
                            </form>
                        </StepContent>
                    </Step>
                </Stepper>

                {activeStep === 2 && (
                    <Box sx={{ mt: 4, textAlign: 'center' }}>
                        <Typography variant="h6" gutterBottom>
                            {t('Thank you for your message!')}
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            {t('We will respond to you as soon as possible.')}
                        </Typography>
                        <StyledButton variant="contained" onClick={() => setActiveStep(0)}>
                            {t('Back to form')}
                        </StyledButton>
                    </Box>
                )}
            </Paper>

            <Paper elevation={3} sx={{ p: 3, borderRadius: '6px', mb: 8 }}>
                <Typography variant="h6" gutterBottom>
                    {t('Need information about using Magistral AI?')}
                </Typography>
                <Typography variant="body1" paragraph>
                    {t('Check our user guides, the answer might be there!')}
                </Typography>
                <Chip
                    icon={<VideoLibraryIcon />}
                    label={t('View our user guides')}
                    onClick={() => navigate('/guide')}
                    clickable
                    sx={{
                        borderRadius: '10px',
                        padding: '8px',
                        '& .MuiChip-label': {
                            padding: '4 8px',
                        },
                        '& .MuiChip-icon': {
                            marginLeft: '8px',
                        }
                    }}
                />
            </Paper>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                message={snackbarMessage}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => setOpenSnackbar(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </Box>
    );
}

export default SupportPage;