import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  useTheme,
  Card,
  CardContent,
  Avatar,
  Tooltip,
  Divider
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import InfoIcon from '@mui/icons-material/Info';
import LightModeIcon from '@mui/icons-material/LightMode';
import SettingsIcon from '@mui/icons-material/Settings';

const useCountUp = (end, duration = 1500) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let startTime;
    let animationFrame;

    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const nextCount = Math.min(Math.floor((progress / duration) * end), end);

      setCount(nextCount);

      if (progress < duration) {
        animationFrame = requestAnimationFrame(step);
      }
    };

    animationFrame = requestAnimationFrame(step);

    return () => cancelAnimationFrame(animationFrame);
  }, [end, duration]);

  return count;
};

const AnimatedNumber = ({ value, suffix }) => {
  const count = useCountUp(value);
  return <>{count.toLocaleString()}{suffix}</>;
};

const StatCard = ({ title, value, icon, color, info, suffix = '' }) => {
  const theme = useTheme();
  return (
    <Card elevation={3} sx={{ height: '100%' }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" component="div" color="text.secondary">
            {title}
            {info && (
              <Tooltip title={info} placement="top">
                <InfoIcon sx={{ fontSize: 16, ml: 1, verticalAlign: 'text-bottom' }} />
              </Tooltip>
            )}
          </Typography>
          <Avatar sx={{ bgcolor: color }}>
            {icon}
          </Avatar>
        </Box>
        <Typography variant="h4" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <AnimatedNumber value={value} suffix={suffix} />
        </Typography>
      </CardContent>
    </Card>
  );
};

const ProjectTypeCard = ({ title, icon, color, projects, avgWords, avgCost }) => {
  const theme = useTheme();
  return (
    <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Avatar sx={{ bgcolor: color, mr: 2 }}>
          {icon}
        </Avatar>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
        <Typography variant="body1">Nombre de projets:</Typography>
        <Typography variant="body1" fontWeight="bold">
          <AnimatedNumber value={projects} />
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
        <Typography variant="body1">Mots moyens par article:</Typography>
        <Typography variant="body1" fontWeight="bold">
          <AnimatedNumber value={avgWords} />
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
        <Typography variant="body1">Coût moyen par article:</Typography>
        <Typography variant="body1" fontWeight="bold">
          <AnimatedNumber value={avgCost} suffix=" €" />
        </Typography>
      </Box>
    </Paper>
  );
};

const Dashboard = () => {
  const theme = useTheme();

  // Données fictives
  const creditsRemaining = 5000;
  const projectsCreated = 125;
  const wordsWritten = 250000;
  const moneySaved = 6250;
  const lightProjects = 80;
  const advancedProjects = 45;
  const avgWordsLight = 1500;
  const avgWordsAdvanced = 2500;
  const avgCostLight = 30;
  const avgCostAdvanced = 50;

  const projectTypeData = [
    { name: 'Lite', value: lightProjects },
    { name: 'Advanced', value: advancedProjects },
  ];

  const monthlyProjectsData = [
    { name: 'Jan', projects: 10 },
    { name: 'Feb', projects: 15 },
    { name: 'Mar', projects: 20 },
    { name: 'Apr', projects: 25 },
    { name: 'May', projects: 30 },
    { name: 'Jun', projects: 35 },
  ];

  const COLORS = [theme.palette.primary.main, theme.palette.secondary.main];

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Crédits restants"
            value={creditsRemaining}
            icon={<AccountBalanceWalletIcon />}
            color={theme.palette.info.main}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Projets créés"
            value={projectsCreated}
            icon={<AssignmentIcon />}
            color={theme.palette.primary.main}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Mots écrits"
            value={wordsWritten}
            icon={<TextFieldsIcon />}
            color={theme.palette.secondary.main}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Économies réalisées"
            value={moneySaved}
            icon={<TrendingUpIcon />}
            color={theme.palette.success.main}
            info="Sur la base de 50€ par article"
            suffix=" €"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Répartition des types de projets</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={projectTypeData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {projectTypeData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <RechartsTooltip />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Projets créés par mois</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={monthlyProjectsData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <RechartsTooltip />
                <Bar dataKey="projects" fill={theme.palette.primary.main} />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <ProjectTypeCard
            title="Projets Lite"
            icon={<LightModeIcon />}
            color={theme.palette.primary.light}
            projects={lightProjects}
            avgWords={avgWordsLight}
            avgCost={avgCostLight}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <ProjectTypeCard
            title="Projets Advanced"
            icon={<SettingsIcon />}
            color={theme.palette.secondary.light}
            projects={advancedProjects}
            avgWords={avgWordsAdvanced}
            avgCost={avgCostAdvanced}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;