import React from 'react';
import { Alert, Slide, Box, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../services/userContext';
import { useTranslation } from 'react-i18next';

const ApiKeyAlert = () => {
    const { user } = useUser();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (user.anthropic_key || user.account_type == 'user') {
        return null;
    }

    const alertStyles = {
        position: 'fixed',
        bottom: 16,
        left: 16,
        zIndex: 9999,
        width: isMobile ? 'calc(100% - 32px)' : 'auto',
        maxWidth: '600px',
    };

    return (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Box sx={alertStyles}>
                <Alert
                    severity="warning"
                    variant="filled"
                    sx={{
                        borderRadius: 2,
                        boxShadow: 3,
                        width: '100%',
                        padding: '12px 16px',
                    }}
                >
                    {t("Vous n'avez pas encore ajouté votre clé API Anthropic. Pour générer des articles, ")}
                    <Box
                        component="span"
                        onClick={() => navigate('/settings')}
                        sx={{
                            color: 'inherit',
                            textDecoration: 'underline',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            '&:hover': {
                                textDecoration: 'none',
                            }
                        }}
                    >
                        {t("ajoutez une clé API maintenant")}
                    </Box>
                    .
                </Alert>
            </Box>
        </Slide>
    );
};

export default ApiKeyAlert;