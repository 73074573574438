import React, { useState, useMemo } from 'react';
import { Box, Container, IconButton, TextField, Button, InputAdornment, Tooltip, CircularProgress, List, ListItem, ListItemText, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CreateProjectModal from './CreateProjectModal';
import { createInventory } from '../services/api';

const InventoryTableMobile = ({ inventory, hasApiKey, user }) => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [page, setPage] = useState(0);
    const pageSize = 5;

    const displayedInventory = useMemo(() =>
        inventory
            .filter(item => item.display === true)
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
        [inventory]
    );

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setPage(0);
    };

    const filteredInventory = displayedInventory.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedInventory = filteredInventory.slice(page * pageSize, (page + 1) * pageSize);

    const handleCreateProject = async (name, mode) => {
        try {
            const newProject = await createInventory(name, mode);
            console.log('New project created:', newProject);
            navigate(`/edit-project/${newProject.inventory.id}`);
        } catch (error) {
            console.error('Failed to create project:', error);
        }
    };

    const renderProjectStatus = (status, id, article_word) => {
        if (status.startsWith('Generating')) {
            const projectType = status.split(' ')[3].replace('...', '');
            return (
                <Tooltip title={<span style={{ color: 'orange' }}>{`Generating ${projectType}`}</span>}>
                    <CircularProgress size={20} sx={{ color: 'orange' }} />
                </Tooltip>
            );
        }
        switch (status) {
            case 'success':
                return (
                    <>
                        <Tooltip title={t('View Article')}>
                            <IconButton
                                onClick={() => navigate(`/view-article/${id}`)}
                                color="primary"
                                aria-label="view article"
                                size="small"
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                        <Typography variant="caption" sx={{ ml: 1 }}>{article_word}</Typography>
                    </>
                );
            case 'edit':
                return (
                    <Tooltip title={t('Edit Project')}>
                        <IconButton
                            onClick={() => navigate(`/edit-project/${id}`)}
                            color="primary"
                            aria-label="edit project"
                            size="small"
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                );
            case 'error':
                return (
                    <Tooltip title={t('Error')}>
                        <ErrorIcon color="error" fontSize="small" />
                    </Tooltip>
                );
            default:
                return null;
        }
    };

    return (
        <Container maxWidth="xl" disableGutters>
            <Box sx={{ p: 0 }}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setIsModalOpen(true)}
                    disabled={!(user.anthropic_key || user.account_type === 'user')}
                    fullWidth
                    sx={{ mb: 4 }}
                >
                    {t('Create Project')}
                </Button>
                <TextField
                    label={t('Search')}
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearch}
                    fullWidth
                    sx={{ mb: 2 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: 'text.secondary' }} />
                            </InputAdornment>
                        ),
                    }}
                />
                <List>
                    {paginatedInventory.map((item) => (
                        <ListItem
                            key={item.id}
                            divider
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                py: 1,
                            }}
                        >
                            <ListItemText
                                primary={item.name}
                                sx={{ mb: 1 }}
                            />
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box
                                        sx={{
                                            width: 12,
                                            height: 12,
                                            borderRadius: '50%',
                                            marginRight: 1,
                                            backgroundColor: item.mode === 'Light' ? '#38bdf8' : item.mode === 'Workflow' ? '#eab308' : 'transparent'
                                        }}
                                    />
                                    <Typography variant="caption" sx={{ mr: 2 }}>
                                        {item.mode === 'Workflow' ? 'Advanced' : item.mode}
                                    </Typography>
                                    <Typography variant="caption">
                                        {new Date(item.created_at).toLocaleDateString()}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {renderProjectStatus(item.status, item.id, item.article_word)}
                                </Box>
                            </Box>
                        </ListItem>
                    ))}
                </List>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button
                        variant="outlined"
                        disabled={page === 0}
                        onClick={() => setPage(prev => prev - 1)}
                    >
                        {t('Précédent')}
                    </Button>
                    <Button
                        variant="outlined"
                        disabled={(page + 1) * pageSize >= filteredInventory.length}
                        onClick={() => setPage(prev => prev + 1)}
                    >
                        {t('Suivant')}
                    </Button>
                </Box>
            </Box>
            <CreateProjectModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onCreateProject={handleCreateProject}
            />
        </Container>
    );
};

export default InventoryTableMobile;