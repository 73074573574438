import React, { useMemo, useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SettingsIcon from '@mui/icons-material/Settings';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import WalletIcon from '@mui/icons-material/Wallet';
import Chip from '@mui/material/Chip';
import { Box, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout as MuiDashboardLayout } from '@toolpad/core/DashboardLayout';
import { useUser } from '../services/userContext';
import { useTranslation } from 'react-i18next';

// Import page components
import Dashboard from '../pages/Dashboard';
import BugReports from '../pages/Support';
import Guide from '../pages/Guide';
import Settings from '../pages/Settings';
import Credits from '../pages/Credits';
import ViewArticle2 from '../pages/ViewArticle2';
import EditProject from '../pages/EditProject';
import Stats from '../pages/Stats';
import LanguageSelector from './LanguageSelector';
import logo from '../assets/images/logo.png';
import logoMobile from '../assets/images/logo.png';
import theme from '../assets/js/theme';
import { getGravatarUrl } from '../utils/gravatar';
import '../custom.css';
import ThemeAwareTitle from './ThemeAwareTitle';
import ThemeAwareTitle2 from './ThemeAwareTitle2';

const CustomToolbarActions = () => {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setIsFullScreen(true);
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
            setIsFullScreen(false);
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!isMobile && (
                <Tooltip title={t(isFullScreen ? 'Exit Fullscreen' : 'Enter Fullscreen')}>
                    <IconButton onClick={toggleFullScreen} color="inherit">
                        {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </IconButton>
                </Tooltip>
            )}
            {!isMobile && <LanguageSelector />}
        </Box>
    );
};

function DashboardLayout() {
    const location = useLocation();
    const navigate = useNavigate();
    const { user, setUser } = useUser();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [session, setSession] = useState(null);

    useEffect(() => {
        if (user) {
            setSession({
                user: {
                    name: user.username,
                    email: user.email,
                    image: getGravatarUrl(user.email),
                }
            });
        }
    }, [user]);

    const authentication = useMemo(() => ({
        signIn: () => console.log("SignIn called in DashboardLayout"),
        signOut: () => {
            setSession(null);
            setUser(null);
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            navigate('/login');
        },
    }), [setUser, navigate]);

    const router = useMemo(() => ({
        pathname: location.pathname,
        searchParams: new URLSearchParams(location.search),
        navigate: (path) => navigate(path),
    }), [location, navigate]);

    const NAVIGATION = useMemo(() => [
        {
            kind: 'header',
            title: t('Main items'),
        },
        {
            segment: 'dashboard',
            title: t('Dashboard'),
            icon: <DashboardIcon />,
        },
        { kind: 'divider' },
        {
            kind: 'header',
            title: t('Help & Contact'),
        },
        {
            segment: 'guide',
            title: t('Usage Guides'),
            icon: <MenuBookIcon />,
        },
        {
            segment: 'support',
            title: t('Support'),
            icon: <QuestionAnswerIcon />,
        },
        { kind: 'divider' },
        {
            kind: 'header',
            title: t('Personal Space'),
        },
        {
            segment: 'settings',
            title: t('My Account'),
            icon: <SettingsIcon />,
        },
        {
            segment: 'credits',
            title: t('My Credits'),
            icon: <WalletIcon />,
            action: <Chip
                label={user.credit}
                color={user.credit < 1000 ? "error" : user.credit <= 2500 ? "warning" : "success"}
                size='small'
            />,
        },
    ], [t, user.credit]);

    if (!user) return null;

    const currentLogo = isMobile ? logoMobile : logo;

    return (
        <AppProvider
            navigation={NAVIGATION}
            router={router}
            branding={{
                logo: (
                    <Box sx={{ display: 'flex', alignItems: 'left', height: '100%' }}>
                        <img
                            src={currentLogo}
                            alt="logo"
                            style={{
                                maxHeight: '100%',
                                maxWidth: '100%',
                                objectFit: 'contain'
                            }}
                        />
                    </Box>
                ),
                title: (
                    user.email === 'demo@magistral.ai' 
                      ? <ThemeAwareTitle />
                      : user.email === 'demo2@magistral.ai'
                        ? <ThemeAwareTitle2 />
                        : ''
                  )
            }}
            theme={theme}
            user={session?.user}
            session={session}
            authentication={authentication}
        >
            <MuiDashboardLayout
                slots={{ toolbarActions: CustomToolbarActions }}
                sx={{
                    '& .MuiToolbar-root': {
                        justifyContent: 'space-between',
                    },
                    '& .MuiToolbar-root > .MuiBox-root:first-of-type': {
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'flex-start',
                    },
                }}
            >
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/support" element={<BugReports />} />
                    <Route path="/guide" element={<Guide />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/view-article/:id" element={<ViewArticle2 />} />
                    <Route path="/edit-project/:id" element={<EditProject />} />
                    <Route path="/credits/" element={<Credits />} />
                    <Route path="/stats/" element={<Stats />} />
                </Routes>
            </MuiDashboardLayout>
        </AppProvider>
    );
}

export default DashboardLayout;