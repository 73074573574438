import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    Tabs,
    Tab,
    CircularProgress,
    Tooltip,
    useMediaQuery,
    alpha,
    Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LinkIcon from '@mui/icons-material/Link';
import DescriptionIcon from '@mui/icons-material/Description';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useTheme, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const InfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
    fontSize: '0.9rem',
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    cursor: 'pointer',
}));

const ScraperModal = ({ open, onClose, onScrape, projectId }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [tabValue, setTabValue] = useState(0);
    const [urls, setUrls] = useState('');
    const [manualSource, setManualSource] = useState('');
    const [manualContent, setManualContent] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState(5);
    const [scraping, setScraping] = useState(false);
    const navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleScrape = () => {
        setScraping(true);
        let data;
        if (tabValue === 0) {
            data = { type: 'urls', content: urls.split('\n') };
        } else if (tabValue === 1) {
            data = { type: 'manual', source: manualSource, content: manualContent };
        } else if (tabValue === 2) {
            data = { type: 'search', query: searchQuery, results: searchResults };
        }
        onScrape(data);
        setScraping(false);
        onClose();
    };

    const renderScrapTab = () => {
        switch (tabValue) {
            case 0:
                return (
                    <TextField
                        fullWidth
                        multiline
                        rows={isMobile ? 3 : 5}
                        value={urls}
                        onChange={(e) => setUrls(e.target.value)}
                        label={t('Ajouter des URLs')}
                        placeholder={t('Une URL par ligne')}
                        variant="outlined"
                        size={isMobile ? "small" : "medium"}
                    />
                );
            case 1:
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: isMobile ? 2 : 2 }}>
                        <TextField
                            fullWidth
                            value={manualSource}
                            onChange={(e) => setManualSource(e.target.value)}
                            label={t('Nom de la source')}
                            variant="outlined"
                            size={isMobile ? "small" : "medium"}
                        />
                        <TextField
                            fullWidth
                            multiline
                            rows={isMobile ? 2 : 3}
                            value={manualContent}
                            onChange={(e) => setManualContent(e.target.value)}
                            label={t('Contenu de la source')}
                            variant="outlined"
                            size={isMobile ? "small" : "medium"}
                        />
                    </Box>
                );
            case 2:
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: isMobile ? 2 : 2 }}>
                        <TextField
                            fullWidth
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            label={t('Requête de recherche')}
                            variant="outlined"
                            size={isMobile ? "small" : "medium"}
                        />
                        <TextField
                            fullWidth
                            type="number"
                            value={searchResults}
                            onChange={(e) => setSearchResults(e.target.value)}
                            label={t('Nombre de résultats')}
                            InputProps={{ inputProps: { min: 1, max: 20 } }}
                            variant="outlined"
                            size={isMobile ? "small" : "medium"}
                        />
                    </Box>
                );
            default:
                return null;
        }
    };

    const TabLabel = ({ label, tooltip }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {label}
            {!isMobile && (
                <Tooltip title={tooltip}>
                    <InfoIcon />
                </Tooltip>
            )}
        </Box>
    );

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobile ? '90%' : 500,
                maxWidth: '100%',
                maxHeight: '90vh',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 1,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 1,
                    bgcolor: alpha(theme.palette.background.paper, 0.9),
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }}>
                    <Typography 
                        variant="h6" 
                        component="h2" 
                        sx={{ 
                            ml: 1,
                            color: theme.palette.primary.main,
                            fontSize: isMobile ? '1rem' : '1.25rem',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {t('Ajouter ou modifier des données')}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{ 
                            color: theme.palette.primary.main
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ 
                    p: 2, 
                    bgcolor: theme.palette.background.default,
                    flexGrow: 1,
                    overflowY: 'auto'
                }}>
                    <Tabs 
                        value={tabValue} 
                        onChange={handleTabChange} 
                        aria-label="scraper tabs" 
                        sx={{ mb: 2 }}
                        variant={isMobile ? "fullWidth" : "standard"}
                    >
                        <Tab 
                            icon={!isMobile && <LinkIcon />}
                            iconPosition="start"
                            label={
                                <TabLabel 
                                    label={t('URLs')} 
                                    tooltip={t('Ajoutez des urls pertinentes et fiables pour votre article et nous allons récupérer le contenu pour alimenter vos données.')}
                                />
                            }
                            sx={{ 
                                fontSize: isMobile ? '0.8rem' : 'inherit',
                                minWidth: 0,
                                px: isMobile ? 1 : 2,
                            }}
                        />
                        <Tab 
                            icon={!isMobile && <DescriptionIcon />}
                            iconPosition="start"
                            label={
                                <TabLabel 
                                    label={t('Manuel')} 
                                    tooltip={t('Ajoutez du contenu textuel en copiant des textes issus de PDF, documents ou de toute autre source. Ils seront ajoutés à vos données.')}
                                />
                            }
                            sx={{ 
                                fontSize: isMobile ? '0.8rem' : 'inherit',
                                minWidth: 0,
                                px: isMobile ? 1 : 2,
                            }}
                        />
                        <Tab 
                            icon={!isMobile && <SearchIcon />}
                            iconPosition="start"
                            label={
                                <TabLabel 
                                    label={t('Recherche')} 
                                    tooltip={t('Ajoutez une intention ou une requête de recherche. Nous interrogerons un moteur de recherche pour récupérer le contenu des premiers résultats.')}
                                />
                            }
                            sx={{ 
                                fontSize: isMobile ? '0.8rem' : 'inherit',
                                minWidth: 0,
                                px: isMobile ? 1 : 2,
                            }}
                        />
                    </Tabs>
                    {renderScrapTab()}
                </Box>
                <Box sx={{ 
                    p: 2, 
                    bgcolor: theme.palette.background.default, 
                    borderTop: `1px solid ${theme.palette.divider}`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    {!isMobile && (
                        <Chip
                            icon={<InfoIcon />}
                            label={t('En savoir plus')}
                            onClick={() => navigate('/guide')}
                            clickable
                            sx={{
                                borderRadius: '10px',
                                padding: '8px',
                                '& .MuiChip-label': {
                                    padding: '4 8px',
                                },
                                '& .MuiChip-icon': {
                                    marginLeft: '8px',
                                }
                            }}
                        />
                    )}
                    <Button
                        onClick={handleScrape}
                        variant="contained"
                        color="primary"
                        disabled={scraping}
                        fullWidth={isMobile}
                        sx={{
                            bgcolor: theme.palette.primary.main,
                            '&:hover': {
                                bgcolor: theme.palette.primary.dark,
                            },
                        }}
                    >
                        {scraping ? <CircularProgress size={24} /> : t('Récupérer le contenu')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ScraperModal;