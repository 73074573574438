import React, { useState, useMemo } from 'react';
import { Box, Container, IconButton, useTheme, useMediaQuery, TextField, Button, InputAdornment, Tooltip, CircularProgress, Chip } from '@mui/material';
import { DataGrid, GridFooterContainer, GridPagination } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import TagIcon from '@mui/icons-material/Tag';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CreateProjectModal from './CreateProjectModal';
import { createInventory, deleteInventory, generateArticle } from '../services/api';
import ProgressButton from './ProgressButton';
import { Language } from '@mui/icons-material';
import { useUser } from '../services/userContext';

const InventoryTable = ({ inventory, onDelete, onEditProject, hasApiKey, user }) => {
    const { t } = useTranslation();
    const [selectionModel, setSelectionModel] = useState([]);
    const theme = useTheme();
    const { dd, setUser } = useUser();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);


    const displayedInventory = useMemo(() =>
        inventory.filter(item => item.display === true).map(item => ({
            ...item,
            cost: item.price,
        })).reverse(),
        [inventory]
    );

    const columns = [
        {
            field: 'name',
            headerName: t('Nom'),
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'tags',
            headerName: t('Tags'),
            width: 140,
            renderCell: (params) => {
                const tags = params.value || [];
                return (
                    <Tooltip title={tags.slice(1).join(', ')}>
                        <Chip
                            icon={<TagIcon />}
                            label={tags[0] || t('No tags')}
                            size="small"
                            sx={{
                                backgroundColor: theme.palette.action.hover,
                                '&:hover': {
                                    backgroundColor: theme.palette.action.selected,
                                },
                            }}
                        />
                    </Tooltip>
                );
            },
        },
        {
            field: 'mode',
            headerName: t('Mode'),
            width: 120,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        sx={{
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            marginRight: 1,
                            backgroundColor: params.value === 'Light' ? '#38bdf8' : params.value === 'Workflow' ? '#eab308' : params.value === 'Legality' ? '#E0B0FF' : params.value === 'Standard' ? '#90EE90' : 'transparent'
                        }}
                    />
                    {params.value === 'Workflow' ? 'Advanced' : params.value}
                </Box>
            )
        },
        {
            field: 'created_at',
            headerName: t('Created'),
            width: 130,
            valueGetter: (params) => new Date(params).toLocaleDateString(),
            hide: isMobile
        },
        {
            field: 'cost',
            headerName: t('Crédits'),
            width: 100,
            renderCell: (params) => `${params.value}`,
        },
        {
            field: 'article_word',
            headerName: t('Mots'),
            width: 80,
            renderCell: (params) => params.value ? params.value : '',
        },
        {
            field: 'actions',
            headerName: t('Actions'),
            width: 130,
            renderCell: (params) => {
                const { status, id } = params.row;
                if (status.startsWith('Generating')) {
                    return <ProgressButton status={status} projectId={id} />;
                }
                switch (status) {
                    case 'success':
                        return (
                            <Button
                                variant="outlined"
                                color="success"
                                size="small"
                                startIcon={<VisibilityIcon />}
                                onClick={() => navigate(`/view-article/${id}`)}
                            >
                                {t('Article')}
                            </Button>
                        );
                    case 'edit':
                        return (
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={() => navigate(`/edit-project/${id}`)}
                            >
                                {t('Éditer')}
                            </Button>
                        );
                    case 'error':
                        return (
                            <Tooltip title={t('Error')}>
                                <IconButton
                                    color="error"
                                    aria-label="error"
                                    size="small"
                                    disabled
                                >
                                    <ErrorIcon />
                                </IconButton>
                            </Tooltip>
                        );
                    default:
                        return null;
                }
            },
        },
    ];

    const handleSelectionChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredInventory = displayedInventory.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (item.tags && item.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase())))
    );

    const handleCreateProject = async (name, mode, tags) => {
        try {
            const newProject = await createInventory(name, mode, tags);
            console.log('New project created:', newProject);
            navigate(`/edit-project/${newProject.inventory.id}`);
        } catch (error) {
            console.error('Failed to create project:', error);
        }
    };

    const handleCreateStandardProject = async (desireArticle, language) => {
        try {
            const newProject = await createInventory('Loading...', 'Standard', [], desireArticle, language);
            console.log('New project created:', newProject);
            const article = generateArticle(newProject.inventory.id);
            const newCredit = user.credit - 170;

            setUser(prev => ({
                ...prev,
                credit: newCredit
            }));

            setIsModalOpen(false);

        } catch (error) {
            console.error('Failed to create project:', error);
        }
    };


    const CustomFooter = () => (
        <GridFooterContainer sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {selectionModel.length > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        onClick={() => {
                            selectionModel.forEach(id => {
                                deleteInventory(id);
                            });
                            setSelectionModel([]);
                        }}
                        color="error"
                        aria-label="delete selected"
                        size="small"
                        sx={{ ml: 1 }}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <Box sx={{ ml: 1, fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' } }}>
                        {t('Elements selected')} ({selectionModel.length})
                    </Box>
                </Box>
            )}

            <Box sx={{ ml: 'auto' }}>
                <GridPagination sx={{ mr: 1 }} />
            </Box>
        </GridFooterContainer>
    );

    return (
        <Container maxWidth="xl" disableGutters>
            <Box sx={{ height: '100%', width: '100%', p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <TextField
                        label={t('Search')}
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={handleSearch}
                        sx={{ width: '400px' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: 'text.secondary' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => setIsModalOpen(true)}
                        disabled={!(user.anthropic_key || user.account_type === 'user')}
                    >
                        {t('Create Project')}
                    </Button>
                </Box>
                <DataGrid
                    rows={filteredInventory}
                    columns={columns}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[10]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={handleSelectionChange}
                    rowSelectionModel={selectionModel}
                    slots={{
                        footer: CustomFooter,
                    }}
                    componentsProps={{
                        columnMenu: {
                            MenuProps: {
                                componentsProps: {
                                    hideManageColumns: true,
                                    hideHideColumn: true,
                                },
                            },
                        },
                    }}
                    localeText={{
                        MuiTablePagination: {
                            labelDisplayedRows: ({ from, to, count }) =>
                                `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`,
                        },
                        columnMenuLabel: t('Menu'),
                        columnMenuShowColumns: t('Afficher les colonnes'),
                        columnMenuFilter: t('Filtrer'),
                        columnMenuHideColumn: t('Cacher'),
                        columnMenuUnsort: t('Annuler le tri'),
                        columnMenuSortAsc: t('Tri croissant'),
                        columnMenuSortDesc: t('Tri décroissant'),
                        columnHeaderSortIconLabel: t('Trier'),
                        filterPanelAddFilter: t('Ajouter un filtre'),
                        filterPanelRemoveAll: t('Supprimer tous les filtres'),
                        filterPanelDeleteIconLabel: t('Supprimer'),
                        filterPanelLogicOperator: t('Opérateur logique'),
                        filterPanelOperator: t('Opérateur'),
                        filterPanelOperatorAnd: t('Et'),
                        filterPanelOperatorOr: t('Ou'),
                        filterPanelColumns: t('Colonnes'),
                        filterPanelInputLabel: t('Valeur'),
                        filterPanelInputPlaceholder: t('Valeur du filtre'),
                        filterOperatorContains: t('Contient'),
                        filterOperatorEquals: t('Égal à'),
                        filterOperatorStartsWith: t('Commence par'),
                        filterOperatorEndsWith: t('Se termine par'),
                        filterOperatorIs: t('Est'),
                        filterOperatorNot: t('N\'est pas'),
                        filterOperatorAfter: t('Après'),
                        filterOperatorOnOrAfter: t('Le ou après'),
                        filterOperatorBefore: t('Avant'),
                        filterOperatorOnOrBefore: t('Le ou avant'),
                        filterOperatorIsEmpty: t('Est vide'),
                        filterOperatorIsNotEmpty: t('N\'est pas vide'),
                        filterOperatorIsAnyOf: t('Est l\'un de'),
                        filterOperatorDoesNotContain: t('Ne contient pas'),
                        filterOperatorDoesNotEqual: t('N\'est pas égal à'),
                        columnMenuManageColumns: t('Gérer les colonnes'),
                        noRowsLabel: t('Aucune donnée'),
                        noResultsOverlayLabel: t('Aucun résultat trouvé'),
                    }}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' }
                        },
                        '& .MuiDataGrid-columnHeader': {
                            fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
                            backgroundColor: theme.palette.action.hover,
                        },
                        width: '100%'
                    }}
                    autoHeight
                />
            </Box>
            <CreateProjectModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onCreateProject={handleCreateProject}
                onCreateStandardProject={handleCreateStandardProject}
            />
        </Container>
    );
};

export default InventoryTable;