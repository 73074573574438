import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    IconButton,
    Chip,
    Divider,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    FormControlLabel,
    Paper,
    Autocomplete,
    TextField,
    Tooltip,
    Slider
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import TuneIcon from '@mui/icons-material/Tune';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import RuleIcon from '@mui/icons-material/Rule';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CreateIcon from '@mui/icons-material/Create';
import { useMediaQuery } from '@mui/material';
import { useUser } from '../services/userContext';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const MOBILE_TRUNCATE_LENGTH = 35;
const DESKTOP_TRUNCATE_LENGTH = 50;

const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    borderRadius: 8,
    padding: '10px 24px',
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    height: 48,
}));

const truncateText = (text, length) => {
    return text.length > length ? `${text.substring(0, length)}...` : text;
};

const InfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    cursor: 'pointer',
}));

const AdditionalSettings = ({
    projectId,
    mainSubject,
    setMainSubject,
    outputLanguage,
    setOutputLanguage,
    additionalElements,
    setAdditionalElements,
    onGenerateArticle,
    generating,
    aiRules,
    setAiRules,
    mode,
    onAdditionalElementsChange,
    longueurArticle,
    setLongueurArticle,
    estimation
}) => {
    const { user } = useUser();
    const { t } = useTranslation();
    const [currentRule, setCurrentRule] = useState('');
    const [currentRuleType, setCurrentRuleType] = useState('required');
    const [localOutputLanguage, setLocalOutputLanguage] = useState(outputLanguage || 'French');
    console.log('localOutputLanguage', localOutputLanguage);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const hasEnoughCredits = user?.credit >= estimation;

    const allowedAdditionalElements = ['faq', 'complexWords', 'seo', 'productList', 'promotion'];

    const ruleSuggestions = {
        seo: [
            "L'intention de recherche que je souhaite mettre en évidence est _",
            "Le mot-clé principal pour lequel je souhaite me classer est _",
        ],
        outline: [
            "Produire minimum 12 outline",
            "Ajouter une outline qui répond à ce sujet : _",
            "Ajouter un tableau comparatif au plan de l'article",
            "N'ajoutez pas trop de listes ordonnées et désordonnées dans l'article",
            "Ajouter une timeline ou une chronologie des événements",
            "Inclure une section avec des exemples concrets",
            "Proposer des conseils pratiques tout au long de l'article",
            "Ajouter des comparaisons ou confrontations d'idées pertinentes pour le sujet qui enrichiraient le contenu",
            "Identifiez les objections principales que le public cible pourrait avoir sur le sujet et proposez d'y répondre dans le contenu",
            "Réalisez une structure pour une section 'Comment faire' ou 'Guide étape par étape'",
            "Ajoutez les mots clés principaux et secondaires dans les outline",
            "L'article doit aussi couvrir les thèmes suivants : _, _, _",
        ],
        writer: [
            "Utiliser des métaphores ou des analogies pour expliquer les concepts complexes",
            "Faire une comparaison entre _ et _",
            "Conclure par un résumé 'A retenir'",
            "Utiliser des exemples de la vie réelle pour illustrer les concepts abstraits",
            "Ajouter des encadrés 'Le saviez-vous?' pour des informations surprenantes",
        ],
        required: [
            "Ajoutez un lien interne _ avec l'ancre _",
            "Ajoutez un lien externe (blank / noindex) _ avec une ancre _",
            "Adapter le contenu au niveau de connaissance du public cible [débutant/intermédiaire/expert]",
            "Utiliser un ton [formel/conversationnel/professionnel] pour cet article",
        ]
    };

    useEffect(() => {
        const savedData = JSON.parse(localStorage.getItem(`project_${projectId}_settings`) || '{}');
        if (savedData.mainSubject) setMainSubject(savedData.mainSubject);
        if (savedData.outputLanguage) {
            setOutputLanguage(savedData.outputLanguage);
            setLocalOutputLanguage(savedData.outputLanguage);
        }
        if (savedData.aiRules) setAiRules(savedData.aiRules);
        if (savedData.additionalElements) {
            const updatedElements = {
                ...savedData.additionalElements,
                seo: true,
                faq: true,
                promotion: true
            };
            setAdditionalElements(updatedElements);
            updateExtras(updatedElements);
        } else {
            const defaultElements = {
                seo: true,
                faq: true,
                promotion: true,
                complexWords: false,
                productList: false
            };
            setAdditionalElements(defaultElements);
            updateExtras(defaultElements);
        }
        if (savedData.longueurArticle) {
            setLongueurArticle(savedData.longueurArticle);
        }
    }, [projectId, setMainSubject, setOutputLanguage, setAdditionalElements, setAiRules, setLongueurArticle]);

    useEffect(() => {
        setLocalOutputLanguage(outputLanguage);
    }, [outputLanguage]);

    const saveSettings = (currentAiRules = aiRules, currentAdditionalElements = additionalElements, currentOutputLanguage = localOutputLanguage, currentLongueurArticle = longueurArticle) => {
        const dataToSave = {
            mainSubject,
            outputLanguage: currentOutputLanguage,
            aiRules: currentAiRules,
            additionalElements: currentAdditionalElements,
            longueurArticle: currentLongueurArticle
        };
        localStorage.setItem(`project_${projectId}_settings`, JSON.stringify(dataToSave));
    };

    const handleAddRule = () => {
        if (currentRule.trim()) {
            const newRule = { type: currentRuleType, content: currentRule.trim() };
            setAiRules(prev => [...prev, newRule]);
            setCurrentRule('');
            saveSettings([...aiRules, newRule]);
        }
    };

    const handleDeleteRule = (index) => {
        const newRules = aiRules.filter((_, i) => i !== index);
        setAiRules(newRules);
        saveSettings(newRules);
    };

    const updateExtras = (elements) => {
        const extras = {
            faq_extra: elements.faq || false,
            seo_extra: elements.seo || false,
            product_list_extra: elements.productList || false,
            definitions_extra: elements.complexWords || false,
            promotion_extra: elements.promotion || false
        };
        onAdditionalElementsChange(extras);
    };

    const handleAdditionalElementChange = (key) => {
        if (allowedAdditionalElements.includes(key) && key !== 'seo' && key !== 'faq' && key !== 'promotion') {
            const newAdditionalElements = { ...additionalElements, [key]: !additionalElements[key] };
            setAdditionalElements(newAdditionalElements);
            saveSettings(aiRules, newAdditionalElements);
            updateExtras(newAdditionalElements);
        }
    };

    const handleOutputLanguageChange = (event) => {
        const newLanguage = event.target.value;
        setLocalOutputLanguage(newLanguage);
        setOutputLanguage(newLanguage);
        saveSettings(aiRules, additionalElements, newLanguage);
    };

    const handleLongueurArticleChange = (event, newValue) => {
        setLongueurArticle(newValue);
        saveSettings(aiRules, additionalElements, localOutputLanguage, newValue);
    };

    const getDisplayType = (type) => {
        switch (type) {
            case 'writer':
                return 'Rédacteur';
            case 'required':
                return 'Règles';
            case 'seo':
                return 'SEO';
            case 'outline':
                return 'Outline';
            default:
                return type;
        }
    };

    const getPlaceholder = (ruleType) => {
        switch (ruleType) {
            case 'required':
                return t("Ajoutez des instructions générales");
            case 'seo':
                return t("Instructions pour le SEO");
            case 'outline':
                return t("Instructions pour le plan d\'article");
            case 'writer':
                return t("Instructions pour le rédacteur IA");
            default:
                return t("Ajoutez vos instructions");
        }
    };

    return (
        <>
            <Paper elevation={3} sx={{ p: 2, mt: 1, bgcolor: 'background.paper' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TuneIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="h6" color="primary">
                        Paramètres essentiels
                    </Typography>
                    <Tooltip title="Indiquez l'intention précise de l'artice, et aussi la langue de rédaction finale">
                        <InfoIcon />
                    </Tooltip>
                </Box>

                <Box sx={{ mt: 1 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={7}>
                            <TextField
                                fullWidth
                                size="small"
                                label={t("Sujet principal de l'article")}
                                value={mainSubject}
                                onChange={(e) => {
                                    setMainSubject(e.target.value);
                                    saveSettings();
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <FormControl fullWidth size="small" variant="outlined" sx={{ minWidth: 200 }}>
                                <InputLabel>{t('Langue de rédaction')}</InputLabel>
                                <Select
                                    value={localOutputLanguage}
                                    onChange={handleOutputLanguageChange}
                                    label={t('Langue de rédaction')}
                                >
                                    <MenuItem value="French">Français</MenuItem>
                                    <MenuItem value="English">Anglais</MenuItem>
                                    <MenuItem value="Spanish">Espagnol</MenuItem>
                                    <MenuItem value="italian">Italien</MenuItem>
                                    <MenuItem value="German">Allemand</MenuItem>
                                    <MenuItem value="Portuguese">Portugais</MenuItem>
                                    <MenuItem value="Breton">Breton</MenuItem>
                                    <MenuItem value="Corsican">Corse</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>

            <Paper elevation={3} sx={{ p: 2, mt: 2, bgcolor: 'background.paper' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TipsAndUpdatesIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="h6" color="primary">
                        {t("Longueur de l'article")}
                    </Typography>
                    <Tooltip title="Indiquez la longueur de l'article que vous souhaitez générer">
                        <InfoIcon />
                    </Tooltip>
                </Box>

                <Box sx={{ mt: 2, mr: 1, ml: 1 }}>
                    <Slider
                        aria-label="LongueurArticle"
                        value={longueurArticle}
                        valueLabelDisplay="auto"
                        step={25}
                        marks={[
                            { value: 0, label: 'court' },
                            { value: 25, label: 'moyen' },
                            { value: 50, label: 'long' },
                            { value: 75, label: 'très long' },
                            { value: 100, label: 'Ultra long' }
                        ]}
                        min={0}
                        max={100}
                        onChange={handleLongueurArticleChange}
                    />
                </Box>
            </Paper>

            <Paper elevation={3} sx={{ p: 2, mt: 2, bgcolor: 'background.paper' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <RuleIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="h6" color="primary">
                        Instructions pour l'IA
                    </Typography>
                    <Typography sx={{ ml: 1 }} variant="subtitle2" color="text.secondary">
                        Facultatif
                    </Typography>
                    <Tooltip title="Indiquez vos instructions pour que notre IA les applique. Vous pouvez également établir des règles concernant le SEO, Outline (le plan de l'article) et la rédaction">
                        <InfoIcon />
                    </Tooltip>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', mb: 1 }}>
                    <FormControl size="small" sx={{ minWidth: 140, mr: isMobile ? 0 : 1, mb: isMobile ? 1 : 0, width: isMobile ? '100%' : 'auto' }}>
                        <Select
                            value={currentRuleType}
                            onChange={(e) => setCurrentRuleType(e.target.value)}
                        >
                            <MenuItem value="required">Instructions</MenuItem>
                            <MenuItem value="seo">SEO</MenuItem>
                            <MenuItem value="outline">Outline</MenuItem>
                            <MenuItem value="writer">Rédaction</MenuItem>
                        </Select>
                    </FormControl>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <Autocomplete
                            freeSolo
                            options={ruleSuggestions[currentRuleType] || []}
                            inputValue={currentRule}
                            onInputChange={(event, newInputValue) => {
                                setCurrentRule(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                    placeholder={getPlaceholder(currentRuleType)}
                                    variant="outlined"
                                />
                            )}
                            sx={{ flexGrow: 1, mr: 1 }}
                        />
                        <IconButton
                            onClick={handleAddRule}
                            color="primary"
                            size="small"
                            sx={{
                                bgcolor: 'primary.dark',
                                color: 'white',
                                '&:hover': { bgcolor: 'primary.main' },
                                width: 36,
                                height: 36,
                                padding: 0,
                                minWidth: 36,
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <AddIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 2 }}>
                    {aiRules.map((rule, index) => (
                        <Tooltip
                            key={index}
                            title={`${getDisplayType(rule.type)}: ${rule.content}`}
                            placement="top"
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                        >
                            <Chip
                                label={
                                    <>
                                        <span style={{ color: 'var(--mui-color-primary-main)', fontWeight: 'bold', marginRight: '4px' }}>
                                            {getDisplayType(rule.type)}:
                                        </span>
                                        {isMobile
                                            ? truncateText(rule.content, MOBILE_TRUNCATE_LENGTH)
                                            : truncateText(rule.content, DESKTOP_TRUNCATE_LENGTH)}
                                    </>
                                }
                                onDelete={() => handleDeleteRule(index)}
                                deleteIcon={<DeleteOutlineIcon style={{ color: 'red' }} />}
                                size="small"
                                sx={{
                                    bgcolor: 'rgba(0, 0, 0, 0.08)',
                                    color: 'text.primary',
                                    '&:hover': {
                                        bgcolor: 'rgba(0, 0, 0, 0.12)',
                                    },
                                    '& .MuiChip-deleteIcon': {
                                        color: 'red',
                                    },
                                }}
                            />
                        </Tooltip>
                    ))}
                </Box>
            </Paper>

            {mode === 'Workflow' && (
                <Paper elevation={3} sx={{ p: 2, mt: 2, bgcolor: 'background.paper' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <PostAddIcon color="primary" sx={{ mr: 1 }} />
                        <Typography variant="h6" color="primary">
                            {t('Éléments supplémentaires')}
                        </Typography>
                        <Typography sx={{ ml: 1 }} variant="subtitle2" color="text.secondary">
                            Facultatif
                        </Typography>
                        <Tooltip title="Ajoutez des éléments supplémentaires à votre article, certains éléments sont déjà générés par défaut.">
                            <InfoIcon />
                        </Tooltip>
                    </Box>

                    <Divider sx={{ mb: 2 }} />

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Grid container spacing={1}>
                            {Object.keys(additionalElements).map((key) => (
                                <Grid item xs={12} sm={6} md={4} key={key}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={additionalElements[key] || false}
                                                onChange={() => handleAdditionalElementChange(key)}
                                                name={key}
                                                color="primary"
                                                size="small"
                                                disabled={!allowedAdditionalElements.includes(key) || key === 'seo' || key === 'faq' || key === 'promotion'}
                                            />
                                        }
                                        label={<Typography variant="body2">{t(key)}</Typography>}
                                        sx={{ m: 0 }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Paper>
            )}

            <Box sx={{ mt: 2, mb: 6, display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip
                    title={!hasEnoughCredits ?
                        "Vous n'avez pas assez de crédits pour générer un article. Veuillez recharger votre compte." :
                        ""}
                    placement="top"
                >
                    <span>
                        <StyledButton
                            variant="contained"
                            color="primary"
                            onClick={onGenerateArticle}
                            disabled={generating || !hasEnoughCredits}
                            startIcon={hasEnoughCredits ? <CreateIcon /> : <AccountBalanceWalletIcon />}
                            sx={{
                                width: '100%',
                                '@media (min-width:600px)': {
                                    width: 'auto',
                                    minWidth: '350px'
                                },
                                bgcolor: hasEnoughCredits ? 'primary.main' : 'error.main',
                                '&:hover': {
                                    bgcolor: hasEnoughCredits ? 'primary.dark' : 'error.dark',
                                },
                                '&:disabled': {
                                    bgcolor: theme => theme.palette.action.disabledBackground,
                                    color: theme => theme.palette.action.disabled,
                                }
                            }}
                        >
                            {generating ? t('Génération en cours...') :
                                !hasEnoughCredits ? t("Crédits insuffisants") :
                                    t(`ENVOYER L'ARTICLE EN RÉDACTION`)}
                        </StyledButton>
                    </span>
                </Tooltip>
            </Box>
        </>
    );
};


export default AdditionalSettings;