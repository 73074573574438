import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useUser } from '../services/userContext';
import InventoryTable from '../components/InventoryTable';
import InventoryTableMobile from '../components/InventoryTableMobile';
import ApiKeyAlert from '../components/ApiKeyAlert';
import EmptyInventory from '../components/EmptyInventory';

function Dashboard() {
    const { user } = useUser();
    const [inventory, setInventory] = useState([]);
    const [hasApiKey, setHasApiKey] = useState(user.anthropic_key);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (user && user.inventory) {
            setInventory(user.inventory);
        }
        setHasApiKey(user.anthropic_key);
    }, [user]);

    // Calculate displayed inventory items
    const displayedInventory = inventory.filter(item => item.display === true);

    const handleDelete = (id) => {
        setInventory(prevInventory => prevInventory.filter(item => item.id !== id));
    };

    const handleCreateProject = () => {
        console.log('Create a new project');
    };

    const InventoryComponent = isMobile ? InventoryTableMobile : InventoryTable;

    return (
        <Box sx={{ p: 3 }}>
            {!hasApiKey && <ApiKeyAlert />}
            {displayedInventory.length === 0 ? (
                <EmptyInventory
                    onCreateProject={handleCreateProject}
                    user={user}
                />
            ) : (
                <InventoryComponent
                    inventory={inventory}
                    onDelete={handleDelete}
                    onCreateProject={handleCreateProject}
                    hasApiKey={hasApiKey}
                    user={user}
                />
            )}
        </Box>
    );
}

export default Dashboard;