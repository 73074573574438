import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

const ThemeAwareTitle2 = () => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '0px' }}>
            <span style={{ color: isDarkMode ? 'white' : 'black' }}>x</span>
            <img
                src="https://www.futura-sciences.com/svg/logo-futura-light.svg"
                alt="Futura Logo"
                style={{
                    maxHeight: '100%',
                    maxWidth: '100%',
                    objectFit: 'contain',
                    marginLeft: '5px',
                    filter: isDarkMode ? 'invert(1)' : 'invert(0)'
                }}
            />
        </Box>
    );
};

export default ThemeAwareTitle2;