import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  useMediaQuery,
  useTheme,
  Skeleton,
  TextField,
  IconButton,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
import LanguageIcon from '@mui/icons-material/Language';
import LogoutIcon from '@mui/icons-material/Logout';
import DownloadIcon from '@mui/icons-material/Download';
import ReceiptIcon from '@mui/icons-material/Receipt';
import EditIcon from '@mui/icons-material/Edit';
import Notification from '../components/Notification';
import { useUser } from '../services/userContext';
import { fetchStripeInvoices } from '../services/api';

// Styled Components
const StyledSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(4),
}));

const StyledCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  transition: 'all 0.3s ease',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
    '& .edit-overlay': {
      opacity: 1
    }
  }
}));

const EditOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: theme.spacing(1),
  opacity: 0,
  transition: 'opacity 0.2s ease',
  display: 'flex',
  gap: theme.spacing(1)
}));

const ContentWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,
  width: '100%',
  position: 'relative'
});

const IconTextWrapper = styled(Box)({
  display: 'inline-flex', // Changé de 'flex' à 'inline-flex'
  alignItems: 'center',
  gap: 8, // Réduit le gap entre l'icône et le texte
  justifyContent: 'center'
});

const Settings = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [language, setLanguage] = useState('fr');
  const [invoices, setInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [notification, setNotification] = useState({ open: false, type: 'success', message: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editValues, setEditValues] = useState({
    username: user?.username || '',
    email: user?.email || ''
  });

  const invoicesPerPage = 5;

  useEffect(() => {
    const loadInvoices = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetchStripeInvoices();
        if (response.success) {
          setInvoices(response.invoices);
        } else {
          throw new Error('Failed to fetch invoices');
        }
      } catch (error) {
        console.error('Failed to fetch invoices:', error);
        setError('Erreur lors du chargement des factures');
        setNotification({
          open: true,
          type: 'error',
          message: 'Erreur lors du chargement des factures'
        });
      } finally {
        setIsLoading(false);
      }
    };

    loadInvoices();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    navigate('/login');
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    if (isMobile) {
      return date.toLocaleDateString('fr-FR', { year: '2-digit', month: '2-digit', day: '2-digit' });
    }
    return date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const formatAmount = (amount, currency) => {
    const formattedAmount = (amount / 100).toFixed(2);
    return isMobile ? `${formattedAmount}€` : `${formattedAmount} ${currency.toUpperCase()}`;
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') return;
    setNotification(prev => ({ ...prev, open: false }));
  };

  const InfoField = ({ 
    icon, 
    value, 
    type = 'text', 
    name,
    disabled = true,
    endAdornment
  }) => {
    return (
      <ContentWrapper>
        <IconTextWrapper>
          <Box sx={{ display: 'flex', alignItems: 'center', lineHeight: 1 }}>
            {icon}
          </Box>
          <TextField
            variant="standard"
            value={value}
            type={type}
            disabled={disabled}
            sx={{
              display: 'inline-block', // Ajouté pour assurer un comportement cohérent
              width: 'auto', // Ajouté pour que le TextField s'adapte au contenu
              '& .MuiInputBase-root': {
                width: 'auto', // Assure que l'input prend la largeur de son contenu
              },
              '& .MuiInputBase-input': {
                textAlign: 'left', // Changé à left pour un meilleur alignement avec l'icône
                fontSize: '1rem',
                transition: 'all 0.2s ease',
                '&:disabled': {
                  color: theme.palette.text.primary,
                  '-webkit-text-fill-color': theme.palette.text.primary,
                },
                padding: 0,
                minWidth: 'auto', // Assure que l'input ne prend pas trop de place
              },
              '& .MuiInput-underline:before': {
                borderBottom: 'none'
              },
              '& .MuiInput-underline:after': {
                borderBottom: 'none'
              },
              '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: 'none'
              },
              '& .MuiInput-underline.Mui-focused:after': {
                borderBottom: 'none'
              }
            }}
            InputProps={{
              endAdornment: endAdornment,
              disableUnderline: true,
            }}
          />
        </IconTextWrapper>
      </ContentWrapper>
    );
  };

  const renderSkeletonRow = (index) => (
    <TableRow key={index}>
      <TableCell>
        <Skeleton animation="wave" width={isMobile ? 80 : 160} />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" width={isMobile ? 60 : 100} />
      </TableCell>
      <TableCell>
        <Skeleton
          animation="wave"
          width={80}
          height={32}
          variant="rectangular"
          sx={{ borderRadius: 1 }}
        />
      </TableCell>
      <TableCell align="right">
        <Skeleton
          animation="wave"
          variant="circular"
          width={32}
          height={32}
          sx={{ ml: 'auto' }}
        />
      </TableCell>
    </TableRow>
  );

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <TableBody>
          {[...Array(5)].map((_, index) => renderSkeletonRow(index))}
        </TableBody>
      );
    }

    if (error) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={4} align="center" sx={{ py: 4 }}>
              <Typography color="error">{error}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    const paginatedInvoices = invoices.slice(
      currentPage * invoicesPerPage,
      (currentPage + 1) * invoicesPerPage
    );

    return (
      <TableBody>
        {paginatedInvoices.map((invoice) => (
          <TableRow key={invoice.id}>
            <TableCell sx={{ p: { xs: 1, sm: 2 }, fontSize: { xs: '0.65rem', sm: '0.875rem' } }}>
              {formatDate(invoice.created)}
            </TableCell>
            <TableCell sx={{ p: { xs: 1, sm: 2 }, fontSize: { xs: '0.65rem', sm: '0.875rem' } }}>
              {formatAmount(invoice.amount_paid, invoice.currency)}
            </TableCell>
            <TableCell sx={{ p: { xs: 1, sm: 2 } }}>
              <Chip
                label={invoice.status === 'paid' ? 'Payée' : 'En attente'}
                color={invoice.status === 'paid' ? 'success' : 'warning'}
                size="small"
                sx={{ fontSize: { xs: '0.625rem', sm: '0.65rem' } }}
              />
            </TableCell>
            <TableCell align="right" sx={{ p: { xs: 1, sm: 2 } }}>
              <Button
                variant="text"
                size="small"
                href={invoice.invoice_pdf}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ minWidth: 'auto', p: { xs: 0.5, sm: 1 } }}
              >
                <DownloadIcon sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }} />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  return (
    <Box sx={{ p: 3, maxWidth: '1280px', margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        Paramètres du compte
      </Typography>
      <Typography variant="subtitle1" gutterBottom color="text.secondary">
        Retrouvez toutes les infos sur votre compte
      </Typography>

      {/* Cartes d'information */}
      <Box sx={{ 
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(4, 1fr)'
        },
        gap: 2,
        mt: 4 
      }}>
    {/* Username Card */}
        <StyledCard elevation={1}>
          <ContentWrapper>
            <InfoField
              icon={<AccountCircleIcon color="action" />}
              value={editValues.username}
              name="username"
            />
          </ContentWrapper>
          <EditOverlay className="edit-overlay">
            <Tooltip title="Bientôt disponible">
              <IconButton size="small" disabled>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </EditOverlay>
        </StyledCard>

        {/* Email Card */}
        <StyledCard elevation={1}>
          <ContentWrapper>
            <InfoField
              icon={<EmailIcon color="action" />}
              value={editValues.email}
              name="email"
              type="email"
            />
          </ContentWrapper>
          <EditOverlay className="edit-overlay">
            <Tooltip title="Bientôt disponible">
              <IconButton size="small" disabled>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </EditOverlay>
        </StyledCard>

        {/* Language Card */}
        <StyledCard elevation={1}>
          <ContentWrapper>
            <LanguageIcon color="action" />
            <Select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              variant="standard"
              sx={{
                textAlign: 'center',
                '&:before': { borderBottom: 'none' },
                '&:hover:not(.Mui-disabled):before': {
                  borderBottom: `1px solid ${theme.palette.primary.main}`
                }
              }}
            >
              <MenuItem value="fr">Français</MenuItem>
              <MenuItem value="en">English</MenuItem>
            </Select>
          </ContentWrapper>
        </StyledCard>

        {/* Logout Card */}
        <StyledCard 
          elevation={1}
          sx={{ 
            '&:hover': { 
              backgroundColor: theme.palette.error.lighter,
            }
          }}
        >
          <Button
            variant="text"
            color="error"
            onClick={handleLogout}
            startIcon={<LogoutIcon />}
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: 'transparent',
              }
            }}
          >
            Se déconnecter
          </Button>
        </StyledCard>
      </Box>

      {/* Section des factures */}
      <StyledSection elevation={2}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
          <ReceiptIcon color="primary" />
          <Typography variant="h6">Factures Stripe</Typography>
        </Box>

        <TableContainer>
          <Table sx={{ minWidth: { xs: 300, sm: 650 } }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ p: { xs: 1, sm: 2 }, fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
                  Date
                </TableCell>
                <TableCell sx={{ p: { xs: 1, sm: 2 }, fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
                  Montant
                </TableCell>
                <TableCell sx={{ p: { xs: 1, sm: 2 }, fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
                  Statut
                </TableCell>
                <TableCell align="right" sx={{ p: { xs: 1, sm: 2 }, fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {renderTableContent()}
          </Table>
        </TableContainer>

        {!isLoading && !error && invoices.length > invoicesPerPage && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button
              disabled={currentPage === 0}
              onClick={() => setCurrentPage(prev => prev - 1)}
            >
              Précédent
            </Button>
            <Button
              disabled={(currentPage + 1) * invoicesPerPage >= invoices.length}
              onClick={() => setCurrentPage(prev => prev + 1)}
            >
              Suivant
            </Button>
          </Box>
        )}
      </StyledSection>

      <Notification
        open={notification.open}
        type={notification.type}
        message={notification.message}
        onClose={handleCloseNotification}
      />
    </Box>
  );
};

export default Settings;