import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  Grid,
  Chip,
  Divider,
  useTheme,
  useMediaQuery,
  InputAdornment,
  Alert,
  Snackbar,
  Avatar,
  Stack
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled, keyframes } from '@mui/material/styles';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SecurityIcon from '@mui/icons-material/Security';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PaymentsIcon from '@mui/icons-material/Payments';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { useUser } from '../services/userContext';
import { createStripeCheckoutSession } from '../services/api';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const CreditPackage = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100%',
  transition: 'all 0.3s ease-in-out',
  borderRadius: '12px',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const BonusChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark'
    ? theme.palette.primary
    : theme.palette.primary,
  color: theme.palette.mode === 'dark'
    ? theme.palette.common.white
    : theme.palette.primary.main,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(0.5),
  '& .MuiChip-icon': {
    color: 'inherit'
  },
  fontWeight: 500,
}));

const FeatureChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '1.1rem',
  padding: '10px 20px',
}));

const CustomAmountBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '12px',
  marginTop: theme.spacing(4),
}));

const CreditAmount = styled(Typography)(({ theme }) => ({
  fontSize: '1.4rem',
  color: theme.palette.text.primary,
  fontWeight: 500,
  marginBottom: theme.spacing(1),
}));

const calculateBonusPercentage = (amount) => {
  if (amount >= 100) return 10;
  if (amount >= 50) return 7.5;
  if (amount >= 25) return 5;
  return 0;
};

const calculateCredits = (amount) => {
  const baseCredits = amount * 100;
  const bonusPercentage = calculateBonusPercentage(amount);
  const bonusCredits = Math.round(baseCredits * (bonusPercentage / 100));
  return {
    base: baseCredits,
    bonus: bonusCredits,
    total: baseCredits + bonusCredits,
    percentage: bonusPercentage
  };
};

const Section = ({ title, icon, children }) => (
  <Paper elevation={3} sx={{ p: 3, mb: 4, borderRadius: '12px' }}>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Avatar sx={{ bgcolor: 'primary.main', mr: 2, width: 32, height: 32 }}>
        {React.cloneElement(icon, { sx: { fontSize: 24 } })}
      </Avatar>
      <Typography variant="h6" component="h2" sx={{ color: 'primary.main' }}>
        {title}
      </Typography>
    </Box>
    <Divider sx={{ mb: 3 }} />
    {children}
  </Paper>
);

const StripeLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="120" height="60" fill="#6772e5">
    <path d="M101.547 30.94c0-5.885-2.85-10.53-8.3-10.53-5.47 0-8.782 4.644-8.782 10.483 0 6.92 3.908 10.414 9.517 10.414 2.736 0 4.805-.62 6.368-1.494v-4.598c-1.563.782-3.356 1.264-5.632 1.264-2.23 0-4.207-.782-4.46-3.494h11.24c0-.3.046-1.494.046-2.046zM90.2 28.757c0-2.598 1.586-3.678 3.035-3.678 1.402 0 2.897 1.08 2.897 3.678zm-14.597-8.345c-2.253 0-3.7 1.057-4.506 1.793l-.3-1.425H65.73v26.805l5.747-1.218.023-6.506c.828.598 2.046 1.448 4.07 1.448 4.115 0 7.862-3.3 7.862-10.598-.023-6.667-3.816-10.3-7.84-10.3zm-1.38 15.84c-1.356 0-2.16-.483-2.713-1.08l-.023-8.53c.598-.667 1.425-1.126 2.736-1.126 2.092 0 3.54 2.345 3.54 5.356 0 3.08-1.425 5.38-3.54 5.38zm-16.4-17.196l5.77-1.24V13.15l-5.77 1.218zm0 1.747h5.77v20.115h-5.77zm-6.185 1.7l-.368-1.7h-4.966V40.92h5.747V27.286c1.356-1.77 3.655-1.448 4.368-1.195v-5.287c-.736-.276-3.425-.782-4.782 1.7zm-11.494-6.7L34.535 17l-.023 18.414c0 3.402 2.552 5.908 5.954 5.908 1.885 0 3.264-.345 4.023-.76v-4.667c-.736.3-4.368 1.356-4.368-2.046V25.7h4.368v-4.897h-4.37zm-15.54 10.828c0-.897.736-1.24 1.954-1.24a12.85 12.85 0 0 1 5.7 1.47V21.47c-1.908-.76-3.793-1.057-5.7-1.057-4.667 0-7.77 2.437-7.77 6.506 0 6.345 8.736 5.333 8.736 8.07 0 1.057-.92 1.402-2.207 1.402-1.908 0-4.345-.782-6.276-1.84v5.47c2.138.92 4.3 1.3 6.276 1.3 4.782 0 8.07-2.368 8.07-6.483-.023-6.85-8.782-5.632-8.782-8.207z" />
  </svg>
);

export default function CreditPurchaseInterface() {
  const { t } = useTranslation();
  const [customCredits, setCustomCredits] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const theme = useTheme();
  const { user } = useUser();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const creditPackages = [
    { amount: 25, price: 25 },
    { amount: 50, price: 50 },
    { amount: 100, price: 100 },
  ];

  const handleCustomCreditsChange = (event) => {
    const value = event.target.value;
    setCustomCredits(value);
  };

  const handleRechargeClick = async () => {
    if (Number(customCredits) < 10) {
      setOpenSnackbar(true);
      return;
    }

    try {
      const { sessionUrl } = await createStripeCheckoutSession(
        `Crédit ${customCredits}€`,
        'one-time',
        Number(customCredits)
      );
      window.location.href = sessionUrl;
    } catch (error) {
      console.error('Error purchasing custom credits:', error);
    }
  };

  const handlePlanPurchase = async (plan, planType, amount) => {
    try {
      const { sessionUrl } = await createStripeCheckoutSession(plan, planType, amount);
      window.location.href = sessionUrl;
    } catch (error) {
      console.error('Error purchasing plan:', error);
    }
  };

  return (
    <Box sx={{ maxWidth: 1280, margin: 'auto', padding: 3 }}>
      <Typography variant="h4" className="custom-title" gutterBottom>
        {t('Your Magistral Credits')}
      </Typography>
      <Typography variant="subtitle1" className="custom-subtitle" sx={{ mb: 4 }}>
        {t('Recharge your credits based on your production rhythm')}
      </Typography>

      <Section title={t('Credit Recharge')} icon={<ShoppingCartIcon />}>
        <Grid container spacing={3}>
          {creditPackages.map((pkg) => {
            const credits = calculateCredits(pkg.amount);
            return (
              <Grid item xs={12} sm={4} key={pkg.amount}>
                <CreditPackage elevation={7}>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'primary.main', mb: 2 }}>
                    {pkg.price}€
                  </Typography>

                  <Stack spacing={1} sx={{ mb: 3 }}>
                    <CreditAmount>
                      {credits.base.toLocaleString()} {t('credits')}
                    </CreditAmount>

                    <BonusChip
                      icon={<CardGiftcardIcon />}
                      label={`+${credits.bonus.toLocaleString()} ${t('bonus credits')}`}
                    />
                  </Stack>

                  <StyledButton
                    variant="outlined"
                    color="primary"
                    startIcon={<AccountBalanceWalletIcon />}
                    fullWidth
                    onClick={() => handlePlanPurchase(`${t('Credit')} ${pkg.price}€`, 'one-time', pkg.price)}
                  >
                    {t('Recharge')}
                  </StyledButton>
                </CreditPackage>
              </Grid>
            )
          })}
        </Grid>

        <CustomAmountBox>
          <Grid container spacing={2} alignItems="start">
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t('Custom amount')}
                variant="outlined"
                type="number"
                value={customCredits}
                onChange={handleCustomCreditsChange}
                placeholder={t('Minimum 10 euros')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <StyledButton
                variant={calculateCredits(Number(customCredits)).bonus > 0 ? "outlined" : "contained"}
                color="primary"
                startIcon={<AccountBalanceWalletIcon />}
                fullWidth
                onClick={handleRechargeClick}
                disabled={!customCredits || Number(customCredits) < 10}
              >
                {customCredits && Number(customCredits) >= 10
                  ? t('Get {{credits}} credits', { credits: calculateCredits(Number(customCredits)).total.toLocaleString() })
                  : t('Recharge')}
              </StyledButton>

              {customCredits && Number(customCredits) >= 25 && (
                <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                  {calculateCredits(Number(customCredits)).base.toLocaleString()} {t('credits')}
                  <Typography
                    component="span"
                    variant="body2"
                    color="primary"
                    sx={{ display: 'inline-flex', alignItems: 'center', gap: 0.5, ml: 1 }}
                  >
                    <CardGiftcardIcon sx={{ fontSize: 12 }} />
                    + {calculateCredits(Number(customCredits)).bonus.toLocaleString()} {t('bonus credits')}
                  </Typography>
                </Typography>
              )}
            </Grid>
          </Grid>
        </CustomAmountBox>
      </Section>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2, mt: 4, mb: 4 }}>
        <FeatureChip icon={<AutorenewIcon />} label={t('No usage limit')} />
        <FeatureChip icon={<SecurityIcon />} label={t('No overconsumption')} />
        <FeatureChip icon={<PaymentsIcon />} label={t('Pay what you use')} />
      </Box>

      <Box sx={{ textAlign: 'center', mt: 4, mb: 8 }}>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          {t('Secure payment by')}
        </Typography>
        <StripeLogo />
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="error"
          sx={{ width: '100%' }}
        >
          {t('Cannot recharge less than 10€')}
        </Alert>
      </Snackbar>

      <Dialog
        open={false}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('Purchase confirmation')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Are you sure you want to proceed with the credit purchase?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { }}>{t('Cancel')}</Button>
          <Button onClick={() => { }} autoFocus>
            {t('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}