import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    "Advanced": "Advanced",
                    "Standard": "Standard",
                    // Navigation sections
                    "Main items": "Workspace",
                    "Help & Contact": "Help & Contact",
                    "Personal Space": "Personal Space",
                    "Test Page": "Test Page",

                    // Navigation items
                    "Dashboard": "My Projects",
                    "Support": "Support",
                    "Usage Guides": "Usage Guides",
                    "My Account": "My Account",
                    "My Credits": "My Credits",
                    "Stats Dashboard": "Stats Dashboard",

                    // Fullscreen
                    "Enter Fullscreen": "Enter Fullscreen",
                    "Exit Fullscreen": "Exit Fullscreen",

                    // Auth related
                    "Sign In": "Sign In",
                    "Sign Out": "Sign Out",

                    // UI Elements
                    "Load more": "Load more",
                    "Show more": "Show more",
                    "Hide": "Hide",
                    "Loading...": "Loading...",
                    "Error": "Error",
                    "Success": "Success",
                    "Warning": "Warning",
                    "Info": "Info",

                    "Bug Reports": "Bug Reports",
                    "Guide": "User Guide",
                    "Settings": "Settings",
                    "Credits": "My Credits",
                    "Social Media": "Social Media",
                    "Main items": "Workspace",
                    "Welcome to Dashboard": "Welcome to Dashboard",
                    "Name": "Name",
                    "Mode": "Mode",
                    "Created": "Created",
                    "Actions": "Actions",
                    "View Article": "View Article",
                    "Edit Project": "Edit Project",
                    "Error": "Error",
                    "Elements selected": "Elements selected",
                    "Search": "Search",
                    "Create Project": "Create Project",
                    "Back": "Back",
                    "Article Details": "Article Details",
                    "ID": "ID",
                    "Created At": "Created At",
                    "Additional Information": "Additional Information",
                    "Estimation": "Estimation",
                    "Language": "Language",
                    "Scrap URLs": "Scrap URLs",
                    "URLs": "URLs",
                    "Manual": "Manual",
                    "Enter URLs (one per line)": "Enter URLs (one per line)",
                    "Enter URL or source name": "Enter source name",
                    "Enter your content here": "Paste your content here",
                    "Search query": "Search query",
                    "Number of results": "Number of results",
                    "Scrape": "Scrape",
                    "Scrapped URLs": "Scrapped URLs",
                    "words": "words",
                    "Additional Settings": "Additional Settings",
                    "Main subject of article": "Main subject of article",
                    "Choose output language": "Choose output language",
                    "Add your AI rules": "Add your AI rules",
                    "Add your additional elements": "Add your additional elements",
                    "faq": "Optimized FAQ",
                    "complexWords": "Complex word definitions",
                    "seo": "SEO (Title, Desc.)",
                    "productList": "Product list in article",
                    "promotion": "Social media posts",
                    "images": "Image ideas for illustration",
                    "relatedArticles": "Related article suggestions",
                    "brief": "Brief summary",
                    "cta": "End of article CTA",
                    "quiz": "Quiz",
                    "affiliate": "Affiliate Box",
                    "googleAds": "Google Ads",

                    // Table and filtering
                    "Contains": "Contains",
                    "Equals": "Equals",
                    "After": "After",
                    "Before": "Before",
                    "Light": "Light",
                    "Workflow": "Advanced",
                    "Filters": "Filters",
                    "Columns": "Columns",
                    "Add filter": "Add filter",
                    "Remove all": "Remove all",
                    "Delete": "Delete",
                    "Logic operator": "Logic operator",
                    "Operator": "Operator",
                    "And": "And",
                    "Or": "Or",
                    "Value": "Value",
                    "Filter value": "Filter value",
                    "Does not contain": "Does not contain",
                    "Does not equal": "Does not equal",
                    "Starts with": "Starts with",
                    "Ends with": "Ends with",
                    "Is empty": "Is empty",
                    "Is not empty": "Is not empty",
                    "Is any of": "Is any of",

                    // Editor
                    "Save": "Save",
                    "Saving...": "Saving...",
                    "Copy": "Copy",
                    "Download": "Download",
                    "Table of Contents": "Table of Contents",
                    "Expand All": "Expand All",
                    "Collapse All": "Collapse All",

                    // Credits page
                    "Your Magistral Credits": "Your Magistral Credits",
                    "Recharge your credits based on your production rhythm": "Recharge your credits based on your production rhythm",
                    "Credit Recharge": "Credit Recharge",
                    "credits": "credits",
                    "bonus credits": "bonus credits",
                    "Recharge": "Recharge",
                    "Custom amount": "Custom amount",
                    "Minimum 10 euros": "Minimum 10 euros",
                    "No usage limit": "No usage limit",
                    "No overconsumption": "No overconsumption",
                    "Pay what you use": "Pay what you use",
                    "Secure payment by": "Secure payment by",
                    "Cannot recharge less than 10€": "Cannot recharge less than 10€",
                    "Purchase confirmation": "Purchase confirmation",
                    "Are you sure you want to proceed with the credit purchase?": "Are you sure you want to proceed with the credit purchase?",
                    "Cancel": "Cancel",
                    "Confirm": "Confirm",

                    // Support page
                    "Support, Help & Suggestions": "Support, Help & Suggestions",
                    "How can we help you today?": "How can we help you today?",
                    "General help": "General help",
                    "Need help using our platform?": "Need help using our platform?",
                    "Report a bug": "Report a bug",
                    "Encountered a technical issue?": "Encountered a technical issue?",
                    "Suggest an improvement": "Suggest an improvement",
                    "Have an idea to improve our service?": "Have an idea to improve our service?",
                    "Your message has been sent successfully!": "Your message has been sent successfully!",
                    "Error sending message.": "Error sending message.",
                    "Need information about using Magistral AI?": "Need information about using Magistral AI?",
                    "Check our user guides, the answer might be there!": "Check our user guides, the answer might be there!",
                    "View our user guides": "View our user guides",

                    // Guide page
                    "Usage Guides": "Usage Guides",
                    "Master Magistral AI with comprehensive tutorials and detailed guides": "Master Magistral AI with comprehensive tutorials and detailed guides",
                    "Watch video": "Watch video",
                    "Video being recorded!": "Video being recorded!",

                    // Settings page
                    "Account Settings": "Account Settings",
                    "Find all your account information": "Find all your account information",
                    "Username": "Username",
                    "Email": "Email",
                    "Current Plan": "Current Plan",
                    "Manage subscription": "Manage subscription",
                    "Last payment date": "Last payment date",
                    "Renewal in": "Renewal in",
                    "days": "days",
                    "Add your Anthropic key": "Add your Anthropic key",
                    "Enter your Anthropic API key": "Enter your Anthropic API key",
                    "How to get your API key?": "How to get your API key?",
                    "Add key": "Add key",
                    "Stripe Invoices": "Stripe Invoices",
                    "Date": "Date",
                    "Amount": "Amount",
                    "Status": "Status",
                    "Paid": "Paid",
                    "Pending": "Pending",

                    // Notifications
                    "Changes saved successfully": "Changes saved successfully",
                    "Error saving changes": "Error saving changes",
                    "API key added successfully": "API key added successfully",
                    "Error adding API key": "Error adding API key",

                    // Stats 
                    "Credits remaining": "Credits remaining",
                    "Projects created": "Projects created",
                    "Words written": "Words written",
                    "Money saved": "Money saved",
                    "Based on 50€ per article": "Based on 50€ per article",
                    "Project type distribution": "Project type distribution",
                    "Projects created per month": "Projects created per month",
                    "Lite projects": "Lite projects",
                    "Advanced projects": "Advanced projects",
                    "Average words per article": "Average words per article",
                    "Average cost per article": "Average cost per article",

                    // Guide titles
                    guide: {
                        title: "Usage Guides",
                        subtitle: "Master Magistral AI with comprehensive tutorials and detailed guides",
                        sections: {
                            quickGuide: "This quick guide (7 min) helps you discover the main features to create your first project from A to Z and explore the different options available.",
                            aiModes: "Magistral offers several AI modes. Light mode, fast and economical, produces condensed articles. Conversely, Advanced mode, which takes longer, generates exceptional quality articles, with in-depth SEO analysis and optimized structure."
                        }
                    },
                    "Produce an article from A to Z": "Produce an article from A to Z",
                    "Light or Advanced?": "Light or Advanced?",
                    "Create a new project": "Create a new project",
                    "Add and edit data": "Add and edit data",
                    "Data statistics": "Data statistics",
                    "Settings, instructions and extras": "Settings, instructions and extras",
                    "View and modify article generation": "View and modify article generation",
                },
            },
            fr: {
                translation: {
                    "Advanced": "Advanced",
                    "Standard": "Standard",
                    // Navigation sections
                    "Main items": "Espace de travail",
                    "Help & Contact": "Aide & Contact",
                    "Personal Space": "Espace Personnel",
                    "Test Page": "Page de test",

                    // Navigation items
                    "Dashboard": "Mes Projets",
                    "Support": "Support",
                    "Usage Guides": "Guides d'utilisation",
                    "My Account": "Mon Compte",
                    "My Credits": "Mes Crédits",
                    "Stats Dashboard": "Tableau de bord statistiques",

                    // Fullscreen
                    "Enter Fullscreen": "Plein écran",
                    "Exit Fullscreen": "Quitter le plein écran",

                    // Auth related
                    "Sign In": "Se connecter",
                    "Sign Out": "Se déconnecter",

                    // UI Elements
                    "Load more": "Charger plus",
                    "Show more": "Afficher plus",
                    "Hide": "Masquer",
                    "Loading...": "Chargement...",
                    "Error": "Erreur",
                    "Success": "Succès",
                    "Warning": "Attention",
                    "Info": "Information",

                    "Bug Reports": "Rapports de bugs",
                    "Guide": "Guide d'utilisation",
                    "Settings": "Paramètres",
                    "Credits": "Mes crédits",
                    "Social Media": "Réseaux sociaux",
                    "Main items": "Espace de travail",
                    "Welcome to Dashboard": "Bienvenue dans le tableau de bord",
                    "Name": "Nom",
                    "Mode": "Mode",
                    "Created": "Créé le",
                    "Actions": "Actions",
                    "View Article": "Voir l'article",
                    "Edit Project": "Modifier le projet",
                    "Error": "Erreur",
                    "Elements selected": "Éléments sélectionnés",
                    "Search": "Rechercher",
                    "Create Project": "Créer un projet",
                    "Back": "Retour",
                    "Article Details": "Détails de l'article",
                    "ID": "ID",
                    "Created At": "Créé le",
                    "Additional Information": "Informations supplémentaires",
                    "Estimation": "Estimation",
                    "Language": "Langue",
                    "Scrap URLs": "URLs à scraper",
                    "URLs": "URLs",
                    "Manual": "Manuel",
                    "Enter URLs (one per line)": "Ajoutez des URLs (une par ligne)",
                    "Enter URL or source name": "Entrez le nom de la source (Url, livre, docs, etc.)",
                    "Enter your content here": "Coller le contenu ici",
                    "Search query": "Requête de recherche",
                    "Number of results": "Nombre de résultats",
                    "Scrape": "Scraper",
                    "Scrapped URLs": "URLs scrapées",
                    "words": "mots",
                    "Additional Settings": "Paramètres additionnels",
                    "Main subject of article": "Sujet principal de l'article",
                    "Choose output language": "Choisissez la langue de sortie",
                    "Add your AI rules": "Ajoutez vos règles d'IA",
                    "Add your additional elements": "Ajoutez vos éléments supplémentaires",
                    "faq": "FAQ optimisée",
                    "complexWords": "Définitions des mots complexes",
                    "seo": "SEO (Titre, slug, desc.)",
                    "productList": "Liste des produits dans l'article",
                    "promotion": "Posts pour les réseaux sociaux",
                    "images": "Idées d'images pour illustrer",
                    "relatedArticles": "Suggestions d'articles connexes",
                    "brief": "Résumé bref",
                    "cta": "CTA de fin d'article",
                    "quiz": "Quiz",
                    "affiliate": "Box Affilié",
                    "googleAds": "Annonces Google",

                    // Table and filtering
                    "Contains": "Contient",
                    "Equals": "Égal à",
                    "After": "Après",
                    "Before": "Avant",
                    "Light": "Light",
                    "Workflow": "Advanced",
                    "Filters": "Filtres",
                    "Columns": "Colonnes",
                    "Add filter": "Ajouter un filtre",
                    "Remove all": "Tout supprimer",
                    "Delete": "Supprimer",
                    "Logic operator": "Opérateur logique",
                    "Operator": "Opérateur",
                    "And": "Et",
                    "Or": "Ou",
                    "Value": "Valeur",
                    "Filter value": "Valeur du filtre",
                    "Does not contain": "Ne contient pas",
                    "Does not equal": "Différent de",
                    "Starts with": "Commence par",
                    "Ends with": "Se termine par",
                    "Is empty": "Est vide",
                    "Is not empty": "N'est pas vide",
                    "Is any of": "Est l'un de",

                    // Editor
                    "Save": "Sauvegarder",
                    "Saving...": "Sauvegarde en cours...",
                    "Copy": "Copier",
                    "Download": "Télécharger",
                    "Table of Contents": "Table des matières",
                    "Expand All": "Tout déplier",
                    "Collapse All": "Tout replier",

                    // Credits page
                    "Your Magistral Credits": "Vos crédits Magistral",
                    "Recharge your credits based on your production rhythm": "Rechargez vos crédits en fonction de votre rythme de production",
                    "Credit Recharge": "Recharge de vos crédits",
                    "credits": "crédits",
                    "bonus credits": "crédits bonus",
                    "Recharge": "Recharger",
                    "Custom amount": "Montant personnalisé",
                    "Minimum 10 euros": "Minimum 10 euros",
                    "Get {{credits}} credits": "Obtenir {{credits}} crédits",
                    "No usage limit": "Pas de limite d'utilisation",
                    "No overconsumption": "Pas de surconsommation",
                    "Pay what you use": "Payez ce que vous consommez",
                    "Secure payment by": "Paiement sécurisé par",
                    "Cannot recharge less than 10€": "Impossible de recharger moins de 10€",
                    "Purchase confirmation": "Confirmation d'achat",
                    "Are you sure you want to proceed with the credit purchase?": "Êtes-vous sûr de vouloir procéder à l'achat de crédits ?",
                    "Cancel": "Annuler",
                    "Confirm": "Confirmer",

                    // Support page
                    "Support, Help & Suggestions": "Support, Aide et Suggestions",
                    "How can we help you today?": "Comment pouvons-nous vous aider aujourd'hui ?",
                    "General help": "Aide générale",
                    "Need help using our platform?": "Besoin d'aide pour utiliser notre plateforme ?",
                    "Report a bug": "Signaler un bug",
                    "Encountered a technical issue?": "Vous avez rencontré un problème technique ?",
                    "Suggest an improvement": "Suggérer une amélioration",
                    "Have an idea to improve our service?": "Une idée pour améliorer notre service ?",
                    "Your message has been sent successfully!": "Votre message a été envoyé avec succès !",
                    "Error sending message.": "Erreur lors de l'envoi du message.",
                    "Need information about using Magistral AI?": "Besoin d'une information concernant l'utilisation de Magistral AI ?",
                    "Check our user guides, the answer might be there!": "Consultez nos guides d'utilisation, la réponse s'y trouve peut-être !",
                    "View our user guides": "Voir nos guides d'utilisation",

                    // Guide page
                    "Usage Guides": "Guides d'utilisation",
                    "Master Magistral AI with comprehensive tutorials and detailed guides": "Maîtrisez Magistral AI grâce à des tutoriels complets et guides détaillés",
                    "Watch video": "Regarder la vidéo",
                    "Video being recorded!": "Vidéo en cours de tournage !",

                    // Settings page
                    "Account Settings": "Paramètres du compte",
                    "Find all your account information": "Retrouvez toutes les infos sur votre compte",
                    "Username": "Nom d'utilisateur",
                    "Email": "Email",
                    "Current Plan": "Plan actuel",
                    "Manage subscription": "Gérer mon abonnement",
                    "Last payment date": "Date du dernier paiement",
                    "Renewal in": "Renouvellement dans",
                    "days": "jours",
                    "Add your Anthropic key": "Ajoutez votre clé Anthropic",
                    "Enter your Anthropic API key": "Entrez votre clé API Anthropic",
                    "How to get your API key?": "Comment obtenir votre clé API ?",
                    "Add key": "Ajouter une clé",
                    "Stripe Invoices": "Factures Stripe",
                    "Date": "Date",
                    "Amount": "Montant",
                    "Status": "Statut",
                    "Paid": "Payée",
                    "Pending": "En attente",

                    // Notifications
                    "Changes saved successfully": "Modifications sauvegardées avec succès",
                    "Error saving changes": "Erreur lors de la sauvegarde",
                    "API key added successfully": "Clé API ajoutée avec succès",
                    "Error adding API key": "Erreur lors de l'ajout de la clé API",

                    // Stats
                    "Credits remaining": "Crédits restants",
                    "Projects created": "Projets créés",
                    "Words written": "Mots écrits",
                    "Money saved": "Économies réalisées",
                    "Based on 50€ per article": "Sur la base de 50€ par article",
                    "Project type distribution": "Répartition des types de projets",
                    "Projects created per month": "Projets créés par mois",
                    "Lite projects": "Projets Lite",
                    "Advanced projects": "Projets Advanced",
                    "Average words per article": "Mots moyens par article",
                    "Average cost per article": "Coût moyen par article",

                    // Guide titles
                    guide: {
                        title: "Guides d'utilisation",
                        subtitle: "Maîtrisez Magistral AI grâce à des tutoriels complets et guides détaillés",
                        sections: {
                            quickGuide: "Ce guide rapide (7 min) vous aide à découvrir les principales fonctionnalités pour créer votre premier projet de A à Z et explorer les différentes options disponibles.",
                            aiModes: "Magistral propose plusieurs modes d'IA. Le mode Light, rapide et économique, produit des articles condensés. À l'inverse, le mode Advanced, plus long, génère des articles d'une qualité exceptionnelle, avec une analyse SEO approfondie et une structure optimisée."
                        }
                    },
                    "Produce an article from A to Z": "Produire un article de A à Z",
                    "Light or Advanced?": "Light ou Advanced ?",
                    "Create a new project": "Créer un nouveau projet",
                    "Add and edit data": "Ajouter et éditer des données",
                    "Data statistics": "Statistiques de vos données",
                    "Settings, instructions and extras": "Paramètres, instructions et extras",
                    "View and modify article generation": "Voir et modifier la génération de l'article",

                    // Additional translations
                    "Statistics": "Statistiques",
                    "Sources and data": "Sources et données",
                    "Edit": "Éditer",
                    "Add": "Ajouter",
                    "No data yet, please add some!": "Vous n'avez pas de données, veuillez en ajouter !",
                    "Add data": "Ajouter des données",
                    "Content Structure": "Structure du contenu",
                    "Word count": "Nombre de mots",
                    "Long paragraphs": "Paragraphes longs",
                    "Long sections": "Sections longues",
                    "Long sentences": "Phrases longues",
                    "Images": "Images",
                    "Links": "Liens",
                    "Previous": "Précédent",
                    "Next": "Suivant",
                    "Loading...": "Chargement...",
                    "An error occurred": "Une erreur est survenue",
                    "Please try again": "Veuillez réessayer",
                    "Close": "Fermer",
                    "Cancel": "Annuler",
                    "Confirm": "Confirmer",
                    "Update": "Mettre à jour",
                    "Warning": "Attention",
                    "Success": "Succès",
                    "Information": "Information"
                }
            }
        },
        lng: "fr",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;