import React from 'react';
import { Box, Typography, Divider, Tooltip, Paper, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';

const InfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    cursor: 'pointer',
}));

const StatBox = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1, 0),
    },
}));

const GaugeContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    height: 20,
    borderRadius: 10,
    backgroundColor: theme.palette.grey[300],
    overflow: 'hidden',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
    },
}));

const GaugeBar = styled(Box)(({ theme, width, color }) => ({
    height: '100%',
    width: `${width}%`,
    backgroundColor: color,
    transition: 'width 0.5s ease-out',
}));

const ThresholdLine = styled(Box)(({ theme, left, color }) => ({
    position: 'absolute',
    left: `${left}%`,
    top: 0,
    height: '100%',
    width: 2,
    backgroundColor: color,
}));

const ThresholdLabel = styled(Typography)(({ theme, left }) => ({
    position: 'absolute',
    left: `${left}%`,
    bottom: -20,
    transform: 'translateX(-50%)',
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.6rem',
    },
}));

const InfoBox = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    border: `1px solid ${theme.palette.divider}`,
}));

const WordCountGauge = ({ wordCount, estimation, numberSources }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const maxWordCount = 20000;
    const thresholds = [0, 3000, 5000, 10000, maxWordCount];
    const thresholdColors = ['#4A4A4A', '#4A4A4A', '#4A4A4A'];
    const progressColors = ['#f44336', '#ff9800', '#4caf50', '#4caf50'];
    const progress = Math.min(100, (wordCount / maxWordCount) * 100);

    const getColor = (count) => {
        if (count < 3000) return progressColors[0];
        if (count < 5000) return progressColors[1];
        if (count < 10000) return progressColors[2];
        return progressColors[3];
    };

    const getExplanatoryText = () => {
        if (wordCount === 0) {
            return "Pas de données, ajoutez des données pour commencer";
        } else if (wordCount < 3000) {
            return "Vous n'avez pas assez de données pour écrire un article exceptionnel";
        } else if (wordCount >= 3000 && wordCount < 5000) {
            return "Vous avez suffisamment de données pour générer un article correct";
        } else if (wordCount >= 5000 && wordCount < 10000) {
            return "Vous avez suffisamment de données pour générer un bon article";
        } else {
            return "Vous avez assez de données pour générer un article exceptionnel";
        }
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <AssessmentIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6" color="primary">
                    Statistiques de vos données
                </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />

            <Box sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                mb: 3
            }}>
                <StatBox elevation={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Estimation des crédits
                        </Typography>
                        <Tooltip title="Nombre de crédits débités pour la rédation de l'article">
                            <InfoIcon />
                        </Tooltip>
                    </Box>
                    <Typography variant="h5" color="primary" fontWeight="bold">
                        {estimation !== null ? Math.round(estimation) : '0'}
                    </Typography>
                </StatBox>
                <StatBox elevation={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Mots dans vos données
                        </Typography>
                        <Tooltip title="Nombre de mots dans vos données">
                            <InfoIcon />
                        </Tooltip>
                    </Box>
                    <Typography variant="h5" color="primary" fontWeight="bold">
                        {wordCount.toLocaleString()}
                    </Typography>
                </StatBox>
                <StatBox elevation={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Nombre de sources
                        </Typography>
                        <Tooltip title="Nombre de sources pour vos données">
                            <InfoIcon />
                        </Tooltip>
                    </Box>
                    <Typography variant="h5" color="primary" fontWeight="bold">
                        {numberSources.toLocaleString()}
                    </Typography>
                </StatBox>
            </Box>

            <GaugeContainer>
                <GaugeBar width={progress} color={getColor(wordCount)} />
                {thresholds.slice(1, -1).map((threshold, index) => (
                    <React.Fragment key={threshold}>
                        <ThresholdLine
                            left={(threshold / maxWordCount) * 100}
                            color={thresholdColors[index]}
                        />
                        <ThresholdLabel left={(threshold / maxWordCount) * 100}>
                            {threshold.toLocaleString()}
                        </ThresholdLabel>
                    </React.Fragment>
                ))}
            </GaugeContainer>

            <InfoBox>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="body2" sx={{ color: getColor(wordCount), flex: 1, mr: 1 }}>
                        {getExplanatoryText()}
                    </Typography>
                    <Tooltip title="Qualité potentielle de l'article basée sur les données ajoutées. Des données entre 5000 et 20000 mots est considéré comme optimal pour la génération d'un article.">
                        <InfoIcon />
                    </Tooltip>
                </Box>
            </InfoBox>
        </Box>
    );
};

export default WordCountGauge;