import React, { useState, useEffect, useRef } from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    Chip,
    Link,
    useTheme,
    Tooltip,
    useMediaQuery,
    alpha
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LinkIcon from '@mui/icons-material/Link';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { updateData } from '../services/api';

const getWordCountColor = (wordCount) => {
    if (wordCount === 0) return 'text.primary';
    if (wordCount < 100) return 'error.main';
    if (wordCount < 300) return 'warning.main';
    return 'success.main';
};

const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
};

const isValidUrl = (url) => {
    return url.startsWith('http://') || url.startsWith('https://');
};

const EditUrlDialog = ({ open, onClose, scrappedUrls, currentIndex, onSave, projectId, setEstimation }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [editedUrls, setEditedUrls] = useState([]);
    const [currentUrlIndex, setCurrentUrlIndex] = useState(currentIndex);
    const [hasChanges, setHasChanges] = useState(false);
    const [saving, setSaving] = useState(false);
    const contentRef = useRef(null);

    useEffect(() => {
        if (open) {
            setEditedUrls([...scrappedUrls]);
            setCurrentUrlIndex(currentIndex);
            setHasChanges(false);
        }
    }, [open, scrappedUrls, currentIndex]);

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.scrollTop = 0;
        }
    }, [currentUrlIndex]);

    const handleDescriptionChange = (e) => {
        const newContent = e.target.value;
        const wordCount = newContent.split(/\s+/).filter(Boolean).length;

        const newEditedUrls = [...editedUrls];
        newEditedUrls[currentUrlIndex] = {
            ...newEditedUrls[currentUrlIndex],
            content: newContent,
            wordCount: wordCount,
        };
        setEditedUrls(newEditedUrls);
        setHasChanges(true);
    };

    const handleDelete = () => {
        const newEditedUrls = editedUrls.filter((_, index) => index !== currentUrlIndex);
        setEditedUrls(newEditedUrls);
        setHasChanges(true);
        if (currentUrlIndex >= newEditedUrls.length) {
            setCurrentUrlIndex(Math.max(0, newEditedUrls.length - 1));
        }
    };

    const handleSave = async () => {
        setSaving(true);
        const updatedData = {};
        editedUrls.forEach(item => {
            updatedData[item.url] = item.content;
        });

        try {
            const response = await updateData(projectId, updatedData, 'Scrap');
            if (response.success && response.estimated_price !== undefined) {
                setEstimation(response.estimated_price);
            }
            onSave(editedUrls);
            setHasChanges(false);
        } catch (error) {
            console.error('Failed to save changes:', error);
        } finally {
            setSaving(false);
        }
    };

    const handlePrevious = () => {
        setCurrentUrlIndex((prev) => Math.max(0, prev - 1));
    };

    const handleNext = () => {
        setCurrentUrlIndex((prev) => Math.min(editedUrls.length - 1, prev + 1));
    };

    const currentUrl = editedUrls[currentUrlIndex];
    const isPdf = currentUrl?.isPdf;
    const wordCount = currentUrl ? currentUrl.wordCount : 0;

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobile ? '90%' : '75%',
                maxWidth: '900px',
                maxHeight: '90vh',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: 1,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 2,
                    bgcolor: alpha(theme.palette.background.paper, 0.9),
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            variant="h6"
                            component="h2"
                            sx={{
                                color: theme.palette.primary.main,
                                fontSize: isMobile ? '1.1rem' : '1.25rem',
                            }}
                        >
                            Modifier ou supprimer des données
                        </Typography>
                        <Tooltip title="Vous pouvez supprimer une source, modifier des données et sauvegarder. Notez que si un contenu est vide ou erroné (en raison du site cible), vous pouvez le copier directement depuis le site et le recoller ici, puis sauvegarder.">
                            <InfoOutlinedIcon sx={{ ml: 1, fontSize: '1rem', color: theme.palette.text.secondary }} />
                        </Tooltip>
                    </Box>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{ color: theme.palette.primary.main }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box sx={{
                    p: 2,
                    flexGrow: 1,
                    overflow: 'auto',
                    bgcolor: theme.palette.background.default,
                }}>
                    {editedUrls.length > 0 ? (
                        <>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                alignItems: isMobile ? 'stretch' : 'center',
                                mb: 2,
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mb: isMobile ? 1 : 0,
                                }}>
                                    <IconButton
                                        onClick={handlePrevious}
                                        disabled={currentUrlIndex === 0}
                                        sx={{
                                            bgcolor: theme.palette.action.hover,
                                            '&:hover': { bgcolor: theme.palette.action.selected },
                                        }}
                                    >
                                        <ChevronLeftIcon />
                                    </IconButton>
                                    <Typography sx={{ mx: 2, userSelect: 'none' }}>
                                        {`${currentUrlIndex + 1} / ${editedUrls.length}`}
                                    </Typography>
                                    <IconButton
                                        onClick={handleNext}
                                        disabled={currentUrlIndex === editedUrls.length - 1}
                                        sx={{
                                            bgcolor: theme.palette.action.hover,
                                            '&:hover': { bgcolor: theme.palette.action.selected },
                                        }}
                                    >
                                        <ChevronRightIcon />
                                    </IconButton>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexGrow: 1,
                                    mx: 2
                                }}>
                                    {isPdf ? (
                                        <PictureAsPdfIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                                    ) : (
                                        <LinkIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                                    )}
                                    {isValidUrl(currentUrl.url) && !isPdf ? (
                                        <Link
                                            href={currentUrl.url}
                                            target="_blank"
                                            variant="body1"
                                            sx={{
                                                color: theme.palette.text.primary,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {truncateText(currentUrl.url, isMobile ? 30 : 50)}
                                        </Link>
                                    ) : (
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {truncateText(currentUrl.url, isMobile ? 30 : 50)}
                                        </Typography>
                                    )}
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: isMobile ? 1 : 0,
                                }}>
                                    {wordCount !== null && (
                                        <Chip
                                            label={`${wordCount} mots`}
                                            size="small"
                                            sx={{
                                                color: getWordCountColor(wordCount),
                                                borderColor: getWordCountColor(wordCount),
                                                backgroundColor: 'transparent',
                                                border: '1px solid',
                                                mr: 1
                                            }}
                                            variant="outlined"
                                        />
                                    )}
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        startIcon={<DeleteOutlineIcon />}
                                        onClick={handleDelete}
                                        size="small"
                                    >
                                        Supprimer
                                    </Button>
                                </Box>
                            </Box>

                            <TextField
                                fullWidth
                                multiline
                                rows={isMobile ? 12 : 16}
                                value={currentUrl.content}
                                onChange={handleDescriptionChange}
                                variant="outlined"
                                disabled={isPdf}
                                sx={{
                                    flexGrow: 1,
                                    '& .MuiOutlinedInput-root': {
                                        height: '100%',
                                        '& textarea': {
                                            height: '100% !important',
                                        },
                                    },
                                }}
                            />
                        </>
                    ) : (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            p: 4,
                        }}>
                            <Typography variant="h6" gutterBottom>
                                Vous n'avez plus de données.
                            </Typography>
                            <Typography variant="body1">
                                Ajoutez-en pour commencer à éditer !
                            </Typography>
                        </Box>
                    )}
                </Box>

                <Box sx={{
                    p: 2,
                    borderTop: `1px solid ${theme.palette.divider}`,
                    display: 'flex',
                    bgcolor: theme.palette.background.default,
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}>
                    <Button onClick={onClose} sx={{ mr: 1 }}>
                        Fermer
                    </Button>
                    <Button
                        onClick={handleSave}
                        variant="contained"
                        color="primary"
                        disabled={!hasChanges || saving}
                    >
                        {saving ? 'Enregistrement...' : 'SAUVEGARDER'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditUrlDialog;