import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    CircularProgress,
    Grid,
    Paper,
    Button,
    styled,
    Modal,
    Divider,
    useTheme,
    useMediaQuery
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    fetchProjectDetails,
    addManualUrl,
    scrapUrls,
    scrapGoogle,
    generateArticle,
    addInstructions
} from '../services/api';
import { useTranslation } from 'react-i18next';
import AdditionalSettings from '../components/AdditionalSettings';
import ScrappedUrls from '../components/ScrappedUrls';
import WordCountGauge from '../components/WordCountGauge';
import ScraperModal from '../components/ScraperModal';
import ConfirmationModal from '../components/ConfirmationModal';
import { Fade } from '@mui/material';
import { useUser } from '../services/userContext';

const DesktopHeader = ({ inventory, handleGoBack }) => (
    <Box sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        borderBottom: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.default',
    }}>
        <Button
            onClick={handleGoBack}
            variant="outlined"
            size="small"
            sx={{ minWidth: 0, p: 1, mr: 2 }}
        >
            <ArrowBackIcon />
        </Button>
        <Typography variant="h6" noWrap sx={{
            flexGrow: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            mr: 2
        }}>
            {inventory.name}
        </Typography>
        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
        <Typography variant="body2" sx={{ flexShrink: 0, mr: 2 }}>
            ID: {inventory.id}
        </Typography>
        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
        <Typography variant="body2" sx={{ flexShrink: 0, mr: 2 }}>
            {inventory.mode === 'Workflow' ? 'Advanced' : inventory.mode}
        </Typography>
        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
        <Typography variant="body2" sx={{ flexShrink: 0 }}>
            {new Date(inventory.created_at).toLocaleDateString()}
        </Typography>
    </Box>
);

const MobileHeader = ({ inventory, handleGoBack }) => (
    <Box sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        borderBottom: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.default',
    }}>
        <Button
            onClick={handleGoBack}
            variant="outlined"
            size="small"
            sx={{ minWidth: 0, p: 1, mr: 2 }}
        >
            <ArrowBackIcon />
        </Button>
        <Typography variant="h6" noWrap sx={{
            flexGrow: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }}>
            {inventory.name}
        </Typography>
    </Box>
);

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    transition: 'box-shadow 0.3s',
    '&:hover': {
        boxShadow: theme.shadows[3],
    },
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
}));

function EditProject() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [projectDetails, setProjectDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [scrappedUrls, setScrappedUrls] = useState([]);
    const [mainSubject, setMainSubject] = useState('');
    const [outputLanguage, setOutputLanguage] = useState('French');
    const [temperature, setTemperature] = useState(0);
    const [longueurArticle, setLongueurArticle] = useState(25);
    const [scraping, setScraping] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [scraperModalOpen, setScraperModalOpen] = useState(false);
    const [isScrapingInProgress, setIsScrapingInProgress] = useState(false);
    const [aiRules, setAiRules] = useState([]);
    const { user, setUser } = useUser();
    const [extras, setExtras] = useState({
        faq_extra: false,
        seo_extra: false,
        product_list_extra: false,
        definitions_extra: false
    });
    const [additionalElements, setAdditionalElements] = useState({
        faq: false,
        complexWords: false,
        seo: false,
        productList: false,
        socialPosts: false,
        images: false,
        relatedArticles: false,
        brief: false,
        cta: false,
        quiz: false,
        affiliate: false,
        googleAds: false
    });
    const [estimation, setEstimation] = useState(null);

    useEffect(() => {
        async function loadProjectDetails() {
            try {
                const details = await fetchProjectDetails(id);
                setProjectDetails(details);

                // Traiter les URLs scrapées
                const scrapedUrlsData = Object.entries(details.data.Scrap || {}).map(([url, content]) => {
                    const wordCount = content.split(/\s+/).filter(Boolean).length;
                    return {
                        url,
                        content,
                        wordCount,
                        isPdf: false
                    };
                });

                // Traiter les fichiers PDF
                const pdfFilesData = details.data.files ?
                    details.data.files.map(filename => ({
                        url: filename,
                        content: "Fichier PDF chargé",
                        wordCount: null, // Retiré le wordCount pour les PDFs
                        isPdf: true,
                        uploaded: true
                    })) : [];

                // Combiner les URLs et les PDFs
                setScrappedUrls([...scrapedUrlsData, ...pdfFilesData]);

                if (details.data.Article && details.data.Article.Estimation) {
                    setEstimation(details.data.Article.Estimation);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }

        loadProjectDetails();
    }, [id]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleAdditionalElementsChange = (newExtras) => {
        setExtras(newExtras);
    };

    const handleGenerateArticle = async () => {
        setGenerating(true);
        try {
            const organizedRules = {
                outline: [],
                required: [],
                seo: [],
                writer: []
            };

            aiRules.forEach(rule => {
                if (organizedRules.hasOwnProperty(rule.type)) {
                    organizedRules[rule.type].push(rule.content);
                }
            });

            const instructionsResult = await addInstructions(
                id,
                outputLanguage,
                organizedRules.outline,
                organizedRules.required,
                organizedRules.seo,
                mainSubject,
                organizedRules.writer,
                extras,
                longueurArticle
            );

            if (instructionsResult.success) {
                localStorage.removeItem(`project_${id}_settings`);
                const result = await generateArticle(id);
                console.log(user.credit);
                const newCredit = user.credit - estimation;
                setUser(prev => ({
                    ...prev,
                    credit: newCredit
                }));

                if (result.success) {
                    setModalOpen(true);
                    setTimeout(() => {
                        setModalOpen(false);
                        navigate('/dashboard');
                    }, 5000);
                }
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setGenerating(false);
        }
    };

    const handleScrape = async (data) => {
        setIsScrapingInProgress(true);
        try {
            let result;
            if (data.type === 'urls') {
                result = await scrapUrls(id, data.content);
            } else if (data.type === 'manual') {
                result = await addManualUrl(id, data.source, data.content);
            } else if (data.type === 'search') {
                result = await scrapGoogle(id, data.query, data.results);
            }

            if (result && result.success) {
                const newScrapedUrls = Object.entries(result.elements).map(([url, content]) => {
                    const wordCount = content.split(/\s+/).filter(Boolean).length;
                    return {
                        url,
                        content,
                        wordCount,
                        isPdf: false
                    };
                });
                setScrappedUrls((prevUrls) => [...prevUrls, ...newScrapedUrls]);

                if (result.estimated_price) {
                    setEstimation(result.estimated_price);
                }
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setIsScrapingInProgress(false);
            setScraperModalOpen(false);
        }
    };

    const getTotalWordCount = (urls) => {
        return urls.reduce((total, url) => total + url.wordCount, 0);
    };

    const updateScrappedUrls = (updatedUrls) => {
        setScrappedUrls(updatedUrls);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 64px)' }}>
                <CircularProgress size={30} />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 2, height: 'calc(100vh - 64px)' }}>
                <Typography color="error" variant="body2">Erreur : {error}</Typography>
            </Box>
        );
    }

    const { inventory } = projectDetails;
    const totalWordCount = getTotalWordCount(scrappedUrls);

    return (
        <Box sx={{ height: 'calc(100vh - 64px)', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
            }}>
                {isMobile
                    ? <MobileHeader inventory={inventory} handleGoBack={handleGoBack} />
                    : <DesktopHeader inventory={inventory} handleGoBack={handleGoBack} />
                }
            </Box>

            <Box sx={{ p: 2, flexGrow: 1, overflow: 'auto' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <StyledPaper elevation={1}>
                            <ScrappedUrls
                                scrappedUrls={scrappedUrls}
                                updateScrappedUrls={updateScrappedUrls}
                                projectId={id}
                                onOpenScraperModal={() => setScraperModalOpen(true)}
                                isScrapingInProgress={isScrapingInProgress}
                                setEstimation={setEstimation}
                            />
                        </StyledPaper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StyledPaper elevation={1}>
                            <WordCountGauge wordCount={totalWordCount} estimation={estimation} numberSources={scrappedUrls.length} />
                        </StyledPaper>
                    </Grid>
                    <Fade in={scrappedUrls.length > 0} timeout={800}>
                        <Grid item xs={12}>
                            {scrappedUrls.length > 0 && (
                                <AdditionalSettings
                                    projectId={id}
                                    mainSubject={mainSubject}
                                    setMainSubject={setMainSubject}
                                    outputLanguage={outputLanguage}
                                    setOutputLanguage={setOutputLanguage}
                                    additionalElements={additionalElements}
                                    setAdditionalElements={setAdditionalElements}
                                    onGenerateArticle={handleGenerateArticle}
                                    generating={generating}
                                    aiRules={aiRules}
                                    setAiRules={setAiRules}
                                    mode={inventory.mode}
                                    onAdditionalElementsChange={handleAdditionalElementsChange}
                                    longueurArticle={longueurArticle}
                                    setLongueurArticle={setLongueurArticle}
                                    estimation={estimation}
                                    isLoading={isScrapingInProgress}
                                />
                            )}
                        </Grid>
                    </Fade>
                </Grid>
            </Box>
            <ConfirmationModal open={modalOpen} onClose={() => setModalOpen(false)} />
            <ScraperModal
                open={scraperModalOpen}
                onClose={() => setScraperModalOpen(false)}
                onScrape={handleScrape}
                projectId={id}
            />
        </Box>
    );
}

export default EditProject;