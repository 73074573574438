import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Divider, useMediaQuery, useTheme, Tabs, Tab } from '@mui/material';
import Editor from '../components/Editor';
import ContenusArticles from '../components/ContenusArticles';
import { fetchProjectDetails } from '../services/api';

const ViewArticle2 = () => {
    const { id } = useParams();
    const [projectDetails, setProjectDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const editorRef = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [activeTab, setActiveTab] = useState(0);

    const CONTENUS_WIDTH = 450;

    useEffect(() => {
        const loadProjectDetails = async () => {
            try {
                const details = await fetchProjectDetails(id);
                setProjectDetails(details);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        loadProjectDetails();
    }, [id]);

    const handleHeadingClick = (headingId) => {
        if (editorRef.current && editorRef.current.scrollToHeading) {
            editorRef.current.scrollToHeading(headingId);
        }
        if (isMobile) {
            setActiveTab(0);
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <Box sx={{ p: 3 }}>Error: {error}</Box>;
    }

    return (
        <Box sx={{ 
            height: 'calc(100vh - 70px)', // Subtract header height
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
        }}>
            {isMobile && (
                <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
                    <Tab label="Article" />
                    <Tab label="Extras" />
                </Tabs>
            )}
            <Box sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                flex: 1,
                overflow: 'hidden',
            }}>
                {isMobile ? (
                    <>
                        {activeTab === 0 && (
                            <Box sx={{ height: 'calc(100% - 48px)', overflow: 'hidden' }}>
                                <Editor ref={editorRef} id={id} details={projectDetails} />
                            </Box>
                        )}
                        {activeTab === 1 && (
                            <Box sx={{ height: 'calc(100% - 48px)', overflow: 'hidden' }}>
                                <ContenusArticles details={projectDetails} onHeadingClick={handleHeadingClick} />
                            </Box>
                        )}
                    </>
                ) : (
                    <>
                        <Box sx={{ 
                            flex: 1,
                            minWidth: 0,
                            overflow: 'hidden'
                        }}>
                            <Editor ref={editorRef} id={id} details={projectDetails} />
                        </Box>
                        <Divider orientation="vertical" flexItem sx={{ borderWidth: '1.5px' }} />
                        <Box sx={{ 
                            width: `${CONTENUS_WIDTH}px`,
                            flexShrink: 0,
                            overflow: 'hidden'
                        }}>
                            <ContenusArticles details={projectDetails} onHeadingClick={handleHeadingClick} />
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default ViewArticle2;