import React, { useState, useRef } from 'react';
import {
    Box,
    Typography,
    List,
    ListItem,
    IconButton,
    Chip,
    Link,
    Button,
    Divider,
    CircularProgress,
    Tooltip,
    useTheme,
    useMediaQuery,
    alpha
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import StorageIcon from '@mui/icons-material/Storage';
import { useTranslation } from 'react-i18next';
import EditUrlDialog from './EditUrlDialog';
import { Skeleton } from '@mui/material';
import { uploadFile, fetchProjectDetails } from '../services/api';

const StyledListItem = styled(ListItem)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(0),
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
}));

const DropZone = styled(Box)(({ theme, isDragging }) => ({
    border: `2px dashed ${isDragging ? theme.palette.primary.main : theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: isDragging ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
    padding: theme.spacing(2),
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    marginBottom: theme.spacing(2)
}));

const UrlContainer = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    minWidth: 0,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const UrlTypography = styled(Typography)(({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
}));

const WordCountChip = styled(Chip)(({ theme }) => ({
    height: 24,
    backgroundColor: 'transparent',
    border: '1px solid',
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
    },
}));

const ScrollableContent = styled(Box)(({ theme }) => `
    flex-grow: 1;
    overflow-y: auto;
    height: calc(100% - 60px);
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(155, 155, 155, 0.5);
        border-radius: 4px;
        border: 2px solid transparent;
        background-clip: content-box;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(155, 155, 155, 0.7);
    }
    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.5) transparent;

    ${theme.breakpoints.down('sm')} {
        height: calc(100% - 80px);
    }
`);

const FixedHeightContainer = styled(Box)(({ theme }) => ({
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
        height: 'auto',
        minHeight: 200,
        maxHeight: 300,
    },
}));

const getWordCountColor = (wordCount) => {
    if (wordCount === 0) return 'text.primary';
    if (wordCount < 100) return 'error.main';
    if (wordCount < 300) return 'warning.main';
    return 'success.main';
};

const isValidUrl = (url) => {
    return url.startsWith('http://') || url.startsWith('https://');
};

const ScrappedUrls = ({ scrappedUrls, updateScrappedUrls, projectId, onOpenScraperModal, isScrapingInProgress, setEstimation }) => {
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const [currentUrlIndex, setCurrentUrlIndex] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const files = Array.from(e.dataTransfer.files).filter(file =>
            file.type === 'application/pdf'
        );

        if (files.length > 0) {
            await handleFiles(files);
        }
    };

    const handleFileSelect = async (e) => {
        const files = Array.from(e.target.files).filter(file =>
            file.type === 'application/pdf'
        );

        if (files.length > 0) {
            await handleFiles(files);
        }
    };

    const handleFiles = async (files) => {
        // Ajouter les fichiers à l'interface avec statut "uploading"
        const newUrls = files.map(file => ({
            url: file.name,
            content: 'Upload en cours...',
            wordCount: null,
            isPdf: true,
            uploading: true
        }));

        updateScrappedUrls([...scrappedUrls, ...newUrls]);

        for (const file of files) {
            try {
                await uploadFile(projectId, file);

                // Mettre à jour l'état du fichier
                updateScrappedUrls(prevUrls =>
                    prevUrls.map(url =>
                        url.url === file.name
                            ? {
                                ...url,
                                content: "Fichier PDF uploadé",
                                uploading: false,
                                uploaded: true
                            }
                            : url
                    )
                );

                // Mettre à jour l'estimation
                const updatedInventory = await fetchProjectDetails(projectId);
                if (updatedInventory?.data?.Article?.Estimation) {
                    setEstimation(updatedInventory.data.Article.Estimation);
                }

            } catch (error) {
                console.error(`Erreur lors de l'upload de ${file.name}:`, error);
                updateScrappedUrls(prevUrls =>
                    prevUrls.map(url =>
                        url.url === file.name
                            ? {
                                ...url,
                                content: `Erreur lors de l'upload: ${error.message}`,
                                uploading: false,
                                error: true
                            }
                            : url
                    )
                );
            }
        }
    };

    const handleEditClick = () => {
        setCurrentUrlIndex(0);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSaveDescription = (updatedUrls) => {
        updateScrappedUrls(updatedUrls);
    };

    const truncateUrl = (url) => {
        if (!isMobile) return url;
        let cleanUrl = url.replace(/^(https?:\/\/)?(www\.)?/, '');
        const slashIndex = cleanUrl.indexOf('/');
        if (slashIndex !== -1) {
            cleanUrl = cleanUrl.substring(0, slashIndex);
        }
        return cleanUrl;
    };

    const renderUrlItem = (item, index) => {
        const IconComponent = item.isPdf ? PictureAsPdfIcon : LinkIcon;
        const wordCountChip = (
            <WordCountChip
                label={item.uploading ? "Upload..." : item.isPdf ? "PDF" : `${item.wordCount} mots`}
                size="small"
                sx={{
                    color: item.uploading
                        ? 'info.main'
                        : item.error
                            ? 'error.main'
                            : item.isPdf
                                ? 'primary.main'
                                : getWordCountColor(item.wordCount),
                    borderColor: item.uploading
                        ? 'info.main'
                        : item.error
                            ? 'error.main'
                            : item.isPdf
                                ? 'primary.main'
                                : getWordCountColor(item.wordCount),
                }}
                variant="outlined"
            />
        );

        return (
            <StyledListItem
                key={index}
                sx={item.error ? { borderLeft: '3px solid', borderColor: 'error.main' } : {}}
            >
                {isMobile ? (
                    <Box display="flex" alignItems="center" width="100%">
                        {wordCountChip}
                        {item.uploading ? (
                            <CircularProgress size={20} sx={{ mx: 1 }} />
                        ) : (
                            <IconComponent
                                sx={{
                                    mx: 1,
                                    color: item.error ? 'error.main' : 'primary.main',
                                    fontSize: 20,
                                    flexShrink: 0
                                }}
                            />
                        )}
                        <UrlTypography variant="body2" noWrap>
                            {truncateUrl(item.url)}
                        </UrlTypography>
                    </Box>
                ) : (
                    <>
                        <UrlContainer sx={{ flexGrow: 1, minWidth: 0 }}>
                            {item.uploading ? (
                                <CircularProgress size={20} sx={{ mr: 1 }} />
                            ) : (
                                <IconComponent
                                    sx={{
                                        mr: 1,
                                        color: item.error ? 'error.main' : 'primary.main',
                                        fontSize: 20,
                                        flexShrink: 0
                                    }}
                                />
                            )}
                            {!item.isPdf && isValidUrl(item.url) ? (
                                <Link
                                    href={item.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    color="inherit"
                                    underline="hover"
                                    onClick={(e) => e.stopPropagation()}
                                    sx={{ display: 'block', width: '100%', minWidth: 0 }}
                                >
                                    <UrlTypography variant="body2">
                                        {item.url}
                                    </UrlTypography>
                                </Link>
                            ) : (
                                <UrlTypography
                                    variant="body2"
                                    color={item.error ? 'error.main' : 'inherit'}
                                >
                                    {item.url}
                                </UrlTypography>
                            )}
                        </UrlContainer>
                        {wordCountChip}
                    </>
                )}
            </StyledListItem>
        );
    };

    const renderHeader = () => (
        <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems={isMobile ? 'flex-start' : 'center'}
            mb={1}
        >
            <Box display="flex" alignItems="center" mb={isMobile ? 1 : 0}>
                <StorageIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6" color="primary">{t('Sources et données')}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
                {isScrapingInProgress ? (
                    <CircularProgress size={24} sx={{ mr: 2 }} />
                ) : (
                    <Button
                        variant="outlined"
                        startIcon={<EditIcon />}
                        onClick={handleEditClick}
                        disabled={scrappedUrls.length === 0}
                        sx={{ mr: 1 }}
                    >
                        {t('Éditer')}
                    </Button>
                )}
                <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={onOpenScraperModal}
                    disabled={isScrapingInProgress}
                >
                    {t('Ajouter')}
                </Button>
            </Box>
        </Box>
    );

    const renderEmptyState = () => (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
            <Typography variant="body1" color="text.secondary" mb={2} textAlign="center">
                {t("Vous n'avez pas de données, veuillez en ajouter !")}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={onOpenScraperModal}
            >
                {t('Ajouter des données')}
            </Button>
        </Box>
    );

    return (
        <FixedHeightContainer>
            {renderHeader()}
            <Divider sx={{ mb: 2 }} />

            <DropZone
                isDragging={isDragging}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={() => fileInputRef.current?.click()}
            >
                <Typography color="text.secondary">
                    {isDragging ?
                        'Déposez vos fichiers PDF ici' :
                        'Glissez et déposez vos fichiers PDF ici ou cliquez pour sélectionner'
                    }
                </Typography>
            </DropZone>

            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileSelect}
                style={{ display: 'none' }}
                accept=".pdf"
                multiple
            />

            <ScrollableContent>
                {isScrapingInProgress ? (
                    <>
                        <Skeleton variant="text" height={40} sx={{ mb: 1 }} />
                        <Skeleton variant="text" height={40} sx={{ mb: 1 }} />
                        <Skeleton variant="text" height={40} sx={{ mb: 1 }} />
                        <Skeleton variant="text" height={40} sx={{ mb: 1 }} />
                        <Skeleton variant="text" height={40} sx={{ mb: 1 }} />
                    </>
                ) : scrappedUrls.length === 0 ? (
                    renderEmptyState()
                ) : (
                    <List dense disablePadding sx={{ width: '100%' }}>
                        {scrappedUrls.map(renderUrlItem)}
                    </List>
                )}
            </ScrollableContent>

            <EditUrlDialog
                open={openDialog}
                onClose={handleCloseDialog}
                scrappedUrls={scrappedUrls}
                currentIndex={currentUrlIndex}
                onSave={handleSaveDescription}
                projectId={projectId}
                setEstimation={setEstimation}
            />
        </FixedHeightContainer>
    );
};

export default ScrappedUrls;