import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    cssVariables: {
        colorSchemeSelector: 'data-toolpad-color-scheme',
    },
    colorSchemes: { light: true, dark: true },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 600,
            lg: 1200,
            xl: 1536,
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
    palette: {
        mode: 'dark',

        background: {
            default: '#1a202c',
            paper: '#242c3a',
        },

    },
});

export default theme;