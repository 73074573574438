import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, LinearProgress, Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled(Paper)(({ theme }) => ({
  position: 'relative',
  width: 400,
  maxWidth: '90%',
  padding: theme.spacing(4),
  outline: 'none',
  borderRadius: theme.shape.borderRadius,
}));

const QuoteBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(2),
  borderLeft: `4px solid ${theme.palette.primary.main}`,
  fontStyle: 'italic',
}));

const quotes = [
  "Tout vient à point à qui sait attendre.",
  "Votre patience sera récompensée.",
  "Ça se prépare doucement mais sûrement.",
  "Un petit moment de suspense…",
  "Chaque chose en son temps.",
  "Ça vaut la peine d’attendre.",
  "Patience, le meilleur reste à venir.",
  "Paris ne s'est pas fait en un jour !",
  "Vous avez le temps d'aller faire couler un café !",
  "Patience, le hamster qui fait tourner nos serveurs fait une sieste.",
];

const ConfirmationModal = ({ open, onClose }) => {
  const [showMessage, setShowMessage] = useState(true);
  const [randomQuote, setRandomQuote] = useState('');

  useEffect(() => {
    if (open) {
      setShowMessage(true);
      setRandomQuote(quotes[Math.floor(Math.random() * quotes.length)]);
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [open]);

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
    >
      <ModalContent elevation={24}>
        <Typography id="confirmation-modal-title" variant="h6" component="h2" gutterBottom>
          {showMessage ? (
            "Votre article a bien été mis en rédaction."
          ) : (
            "Cela peut prendre plusieurs minutes."
          )}
        </Typography>
        <Box sx={{ width: '100%', my: 3 }}>
          <LinearProgress />
        </Box>
        <QuoteBox>
        <   Typography variant="h5" sx={{ fontWeight: 'bold' }}>{randomQuote}</Typography>
        </QuoteBox>
      </ModalContent>
    </StyledModal>
  );
};

export default ConfirmationModal;