import React, { useEffect, useState, useCallback } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { useUser } from '../services/userContext';
import { fetchUserInfo } from '../services/api';
import { initSocket, joinRoom, disconnect } from '../services/socketService';
import DashboardLayout from './DashboardLayout';

function AuthenticatedApp() {
    const { user, setUser } = useUser();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const updateInventory = useCallback((inventoryUpdate) => {
        setUser(prevUser => {
            if (!prevUser) return prevUser;

            let updatedInventory;

            if (inventoryUpdate.action === 'create') {
                updatedInventory = [...prevUser.inventory, inventoryUpdate.inventory];
            } else if (inventoryUpdate.action === 'update') {
                updatedInventory = prevUser.inventory.map(item =>
                    item.id === inventoryUpdate.inventory.id ? { ...item, ...inventoryUpdate.inventory } : item
                );
            } else if (inventoryUpdate.action === 'delete') {
                updatedInventory = prevUser.inventory.filter(item => item.id !== inventoryUpdate.inventory.id);
            } else {
                return prevUser;
            }

            return { ...prevUser, inventory: updatedInventory };
        });
    }, [setUser]);

    useEffect(() => {
        let isMounted = true;

        async function initializeApp() {
            try {
                // Fetch user info
                const userInfo = await fetchUserInfo();
                if (isMounted) {
                    setUser(prevUser => ({ ...prevUser, ...userInfo }));
                }

                // Always initialize socket connection
                if (isMounted && userInfo && userInfo.room) {
                    console.log('Initializing socket connection');
                    const { connectPromise } = initSocket(updateInventory);
                    await connectPromise;
                    await joinRoom(userInfo.room);
                }
            } catch (err) {
                console.error('Error initializing app:', err);
                if (isMounted) {
                    setError(err.message);
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        }

        initializeApp();

        // Cleanup function
        return () => {
            isMounted = false;
            console.log('Disconnecting socket');
            disconnect();
        };
    }, [setUser, updateInventory]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return <DashboardLayout />;
}

export default AuthenticatedApp;